import React, { useState } from 'react';
import { Link } from "react-router-dom";
import * as Img from '../../components/Img';
import Masonry from "react-masonry-css";

const Trader = () => {
    const testimonials = [
        {
            id: 1,
            name: "Jeck Rao",
            image: Img.T1,
            text: "The dashboard is very intuitive and user-friendly and the support team is very responsive and helpful...",
        },
        {
            id: 2,
            name: "Anil Malhotra",
            image: Img.T3,
            text: "I made payouts after payouts from multiple accounts and every time I received my money in just 6-7 hours maximum...",
        },
        {
            id: 3,
            name: "Tony Fergurson",
            image: Img.T3,
            text: "FundedFirm shines! Completing phases 1 and 2 has been incredible, and now I'm embarking on a promising journey ahead...",
        },
        {
            id: 4,
            name: "Pranav Raval",
            image: Img.T4,
            text: "The dashboard is very intuitive and user-friendly and the support team is very responsive and helpful...",
        },
        {
            id: 5,
            name: "Richard Arwin",
            image: Img.T2,
            text: "FundedFirm rocks! Today, Amy from their customer service team helped me with inactivity issues. She clarified rules...",
        },
        {
            id: 6,
            name: "Vikram Singh",
            image: Img.T7,
            text: "The dashboard is very intuitive and user-friendly and the support team is very responsive and helpful...",
        },
        {
            id: 7,
            name: "Ekta Kapur",
            image: Img.T2,
            text: "Fantastic assistance! Technical support swiftly resolved an account issue in under 36 hours...",
        },
        {
            id: 8,
            name: "Sofia Conte",
            image: Img.T4,
            text: "5-star for payout as promised including evaluation fee I had a different experience in the past, but anyway...",
        },
        {
            id: 9,
            name: "Divya Joshi",
            image: Img.T5,
            text: "With FundedFirm, I feel like a valued trader. Their raw spreads and low commissions make a huge difference in my trading results.",
        },
        {
            id: 10,
            name: "Imran",
            image: Img.T3,
            text: "I got to win an account by playing a game! How cool is that! The mods are totally helpful, supportive and the overall community is so cool...",
        },
        {
            id: 11,
            name: "Shweta mehta",
            image: Img.T6,
            text: "The dashboard is very intuitive and user-friendly and the support team is very responsive and helpful...",
        },
        {
            id: 12,
            name: "Joshua Nadiri",
            image: Img.T1,
            text: "FundedFirm rocks! Today, Amy from their customer service team helped me with inactivity issues...",
        },
        {
            id: 13,
            name: "Oliver",
            image: Img.T1,
            text: "FundedFirm changed the game for me with their 15% profit share right from the challenge phase—highly recommend!",
        },
        {
            id: 14,
            name: "Rohan Pandey",
            image: Img.T4,
            text: "FundedFirm shines! Completed phases 1 and 2, now on a journey. Keep up the great work, 5 stars from me!",
        },
        {
            id: 15,
            name: "Aditya Roy",
            image: Img.T1,
            text: "FundedFirm transformed my finances! Before, minimal profits; now, significant progress. A thousand blessings for this opportunity!",
        },
        {
            id: 16,
            name: "Karthik Babu",
            image: Img.T4,
            text: "The path to financial freedom Very good evening, thank you for changing my life before belonging to the FundedFirm family...",
        },
        {
            id: 17,
            name: "Sneha Patel",
            image: Img.T2,
            text: "The path to financial freedom Very good evening, thank you for changing my life before belonging to the FundedFirm family...",
        },
    ];

    const breakpointColumnsObj = {
        default: 6,
        1400: 5,
        1100: 3,
        768: 2,
        500: 1,
    };


    const groupedTestimonials = [];
    for (let i = 0; i < testimonials.length; i += 3) {
        groupedTestimonials.push(testimonials.slice(i, i + 3));
    }

    return (
        <>
            <div className='traders-section p-80' style={{
                backgroundImage: `url(${Img.Traders})`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                position: 'relative',
            }}>
                <div
                    className="tradline-background"
                    style={{
                        // backgroundImage: `url(${Img.Tradline})`,
                        backgroundPosition: 'bottom left',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                    }}
                ></div>

                <div className='container-fluid row-3'>
                    <div className='custom-heading ch-medium text-center' data-aos="fade-up">
                    Our Efforts <span>Your profits ❤️</span>
                    </div>
   

                      {/* Display the current set of testimonials */}
                    <div className='masonry-s p-0 d-block d-md-none' data-aos="fade-up">
                        <div className="my-masonry-grid vsdfgbsrhgsrhbsrh">
                        {groupedTestimonials.map((group, groupIndex) => (
                        <div key={groupIndex} className="set">
                            {group.map((testimonial) => (
                                <div key={testimonial.id} className="card">
                                    <div className="ts-inner">
                                        <img src={testimonial.image} alt={testimonial.name} />
                                        <h3>{testimonial.name}</h3>
                                    </div>
                                    <p>{testimonial.text}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                        </div>
                    </div>

                    <div className='masonry-s d-none d-md-block' data-aos="fade-up">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {testimonials.map((testimonial, index) => (
                                <div
                                    key={testimonial.id}
                                    className={`card ${index % 2 === 0 ? "odd" : ""} row-1`}
                                    data-scroll
                                    data-scroll-speed={index % 2 === 0 ? "-1" : "1"}
                                >
                                    <div className='ts-inner'>
                                        <img src={testimonial.image} alt={testimonial.name} />
                                        <h3>{testimonial.name}</h3>
                                    </div>
                                    <p>{testimonial.text}</p>
                                </div>
                            ))}
                        </Masonry>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Trader;
