import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Header section
import AosLayout from "../components/AosLayout";
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../container/Header/Header";

// Pages
import Home from "../container/PageHome/Home";
import Tradingrules from "../container/PageTradingrules/Tradingrules";
import Blog from "../container/PageBlog/Blog";
import Blogdetail from "../container/PageBlogdetail/Blogdetail";
import Blogdetail2 from "../container/PageBlogdetail/Blogdetail2";
import Blogdetail3 from "../container/PageBlogdetail/Blogdetail3";
import Blogdetail4 from "../container/PageBlogdetail/Blogdetail4";
import Blogdetail5 from "../container/PageBlogdetail/Blogdetail5";
import Contact from "../container/PageContactus/Contact";
import Faqs from "../container/PageFaq/Faqs";
import Privacypolicy from "../container/PagePrivacypolicy/Privacypolicy";
import Termsconditions from "../container/PageTermsconditions/Termsconditions";
import Cookiepolicy from "../container/PageCookiepolicy/Cookiepolicy";
import Amlpolicy from "../container/PageAmlpolicy/Amlpolicy";
import Affiliateprogram from "../container/PageAffiliateprogram/Affiliateprogram";
import Footer from "../container/Footer/Footer";

export const pages = [
  { path: "/", component: Home },
  { path: "/trading-rules", component: Tradingrules },
  { path: "/blog", component: Blog },
  { path: "/blog/maximizeyour", component: Blogdetail },
  { path: "/blog/yourbestallies", component: Blogdetail2 },
  { path: "/blog/magicbehind", component: Blogdetail3 },
  { path: "/blog/frombeginner", component: Blogdetail4 },
  { path: "/blog/performancebased", component: Blogdetail5 },
  { path: "/faq", component: Faqs },
  { path: "/contact", component: Contact },
  { path: "/privacy-policy", component: Privacypolicy },
  { path: "/terms-conditions", component: Termsconditions },
  { path: "/cookie-policy", component: Cookiepolicy },
  { path: "/aml-policy", component: Amlpolicy },
  { path: "/affiliate-program", component: Affiliateprogram },
];

const MyRoutes = () => {


  return (
    <>
      <Loader />

      {/* <div
        className={`pattern-bx ${isLoaded ? "visible" : "hidden"}`}
        style={{ transition: "opacity 0.1s ease", opacity: isLoaded ? 1 : 1 }}
      >
        <div className="gradient-background-bx">
          <div className="gb-inner">
            <div className="gb-shape gb-s-2"></div>
          </div>
        </div>
      </div> */}

        <ScrollToTop />
        <Header />
        <Routes>
          {pages.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
        </Routes>
        <Footer />
     
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <AosLayout>
        <MyRoutes />
      </AosLayout>
    </Router>
  );
}

export default MainRoutes;
