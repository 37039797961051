import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import * as Img from '../../components/Img';

const Getstart = () => {


    return (
        <>
        
            <div className='getstart-section p-80'>
                <div className='container row-3'>
                    <div className='custom-heading ch-medium' data-aos="fade-up">
                        Get started today
                    </div>
                    <div className='custom-heading ch-xssmall' data-aos="fade-up">
                        Ready to take your trading to the next level? Get started today with FundedFirm and unlock the potential of professional trading with the best tools and expert support.
                    </div>

                    <div className='csdgvsrgf'>
                        <img data-aos="zoom-in-up"   className='xsfvsgrsg' src={Img.Mask2} alt='' />
                        <div className='gss-inner' data-aos="fade-up" data-aos-delay="700">
                            <div className="evaluation-card ec-card">
                                <img src={Img.Dashboardone} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Getstart;
