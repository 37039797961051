import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AosLayout = ({ children }) => {
    useEffect(() => {
        setTimeout(() => {
            AOS.init({
                offset: 120,
                easing: 'ease-out',
                duration: 1000,
                delay:300,
            });
        }, 2000);
    }, []);

    return <>{children}</>;
};

export default AosLayout;
