import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import * as Img from '../../components/Img';
import Icon from '../../components/Icon';
import "../../css/header.css";

const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [headerSticky, setheaderSticky] = useState("");

     // --- ---
     useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setheaderSticky("active");
            } else {
                setheaderSticky("");
            }
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Remove the event listener when the component unmounts to avoid memory leaks
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    
    return (
        <>
        
  
        <header  className={`navbar ${headerSticky}`}>
            <div className="container top-header-s" data-aos="fade-down" data-aos-once="true" data-aos-delay="600" data-aos-duration="1500" >
                {/* Logo */}
                <div className="logo">
                    <Link to="/">
                        <img src={Img.logo} alt="logo" className='desktoplogo' />
                        <img src={Img.Mobilelogo} alt="logo" className='mobilelogo' />
                    </Link>
                </div>

                {/* Navigation Menu */}
                <nav className='menu'>
                    <Link to="/"><span>0.1 /</span> Home</Link>
                    <Link to="/trading-rules"><span>0.2 /</span> Trading Rules</Link>
                    <Link to="/blog"><span>0.3 /</span> Blog</Link>
                    <Link to="/faq"><span>0.4 /</span> FAQ</Link>
                    <Link to="/contact"><span>0.5 /</span> Contact Us</Link>
                </nav>

               
                <div className="auth-buttons">
                    <Link to="https://my.fundedfirm.com/" target='_blank' className="btn-login login-n">
                        Login
                    </Link>
                    <Link to="https://my.fundedfirm.com/register" target='_blank' className="button-transparent">
                        Get Funded <span><Icon.ArrowrightSvg /></span>
                    </Link>
                    <div className="hamburger" onClick={toggleMenu}>
                        <span className='line'></span>
                        <span className='line'></span>
                        <span className='line'></span>
                    </div>
                </div>

            </div>
        </header>

        <div className={`sidebar afvsdgvbsrg ${menuOpen ? 'open' : ''}`}>
                    <div className="sidebar-header">
                        <div className="logo">
                            <Link to="/">
                                <img src={Img.logo3} alt="logo" />
                            </Link>
                        </div>
                        <span className="close-btn" onClick={toggleMenu}>
                            &times;
                        </span>
                    </div>

                    <div className="nlb-logo">
                        <Link to="https://my.fundedfirm.com/" target='_blank' className="new-login-btn">Login</Link>
                        <Link to="https://my.fundedfirm.com/register" target='_blank' className="new-login-btn">Get Funded</Link>
                    </div>

                    <nav className="sidebar-menu">
                        <Link to="/" onClick={toggleMenu}>Home</Link>
                        <Link to="/trading-rules" onClick={toggleMenu}>Trading Rules</Link>
                        <Link to="/blog" onClick={toggleMenu}>Blog</Link>
                        <Link to="/faq" onClick={toggleMenu}>FAQ</Link>
                        <Link to="/contact" onClick={toggleMenu}>Contact Us</Link>
                    </nav>
                </div>
                {menuOpen && <div className="afvsdgvbsrg overlay" onClick={toggleMenu}></div>}
        </>

        
    );
};

export default Header;
