import React, { useState, useEffect, useRef } from "react";
import Icon from "../../components/Icon";

const Pricetable = () => {
    const [activeTab, setActiveTab] = useState("5K$");

    const [activeStep, setActiveStep] = useState("step1box");

    const cardsRef = useRef();

    const plans = {
        "5K$": [
            { type: "Step 1", price: "$69", details: { challengePhase: "$69", ChallengeType: "1 Step", ProfitTarget: "10%", MaximumDailyLoss: "3%", MaximumOverallLoss: "6%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
            { type: "Step 2", price: "$59", details: { challengePhase: "$59", ChallengeType: "2 Step", ProfitTarget: "P1:8%, P2:5%", MaximumDailyLoss: "5%", MaximumOverallLoss: "10%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
        ],
        "15K$": [
            { type: "Step 1", price: "$135", details: { challengePhase: "$135", ChallengeType: "1 Step", ProfitTarget: "10%", MaximumDailyLoss: "3%", MaximumOverallLoss: "6%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
            { type: "Step 2", price: "$125", details: { challengePhase: "$125", ChallengeType: "2 Step", ProfitTarget: "P1:8%, P2:5%", MaximumDailyLoss: "5%", MaximumOverallLoss: "10%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
        ],
        "25K$": [
            { type: "Step 1", price: "$225", details: { challengePhase: "$225", ChallengeType: "1 Step", ProfitTarget: "10%", MaximumDailyLoss: "3%", MaximumOverallLoss: "6%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
            { type: "Step 2", price: "$199", details: { challengePhase: "$199", ChallengeType: "2 Step", ProfitTarget: "P1:8%, P2:5%", MaximumDailyLoss: "5%", MaximumOverallLoss: "10%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
        ],
        "50K$": [
            { type: "Step 1", price: "$335", details: { challengePhase: "$335", ChallengeType: "1 Step", ProfitTarget: "10%", MaximumDailyLoss: "3%", MaximumOverallLoss: "6%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
            { type: "Step 2", price: "$299", details: { challengePhase: "$299", ChallengeType: "2 Step", ProfitTarget: "P1:8%, P2:5%", MaximumDailyLoss: "5%", MaximumOverallLoss: "10%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
        ],
        "100K$": [
            { type: "Step 1", price: "$549", details: { challengePhase: "$549", ChallengeType: "1 Step", ProfitTarget: "10%", MaximumDailyLoss: "3%", MaximumOverallLoss: "6%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
            { type: "Step 2", price: "$499", details: { challengePhase: "$499", ChallengeType: "2 Step", ProfitTarget: "P1:8%, P2:5%", MaximumDailyLoss: "5%", MaximumOverallLoss: "10%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
        ],
        "200K$": [
            { type: "Step 1", price: "$999", details: { challengePhase: "$999", ChallengeType: "1 Step", ProfitTarget: "10%", MaximumDailyLoss: "3%", MaximumOverallLoss: "6%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
            { type: "Step 2", price: "$949", details: { challengePhase: "$949", ChallengeType: "2 Step", ProfitTarget: "P1:8%, P2:5%", MaximumDailyLoss: "5%", MaximumOverallLoss: "10%", MinimumTrading: "5 Days", ProfitSplitUpto: "100%", NewsTrading: "✔" } },
        ],
    };

    const switchTab = (tab) => {
        setActiveTab(tab);
    };

    const step1Tab = () => {
        setActiveStep("step1box");
    };

    const step2Tab = () => {
        setActiveStep("step2box");
    };


    return (
        <>
            <div className="pricetable p-80" id="choosebestplan">
                <div className="container-fluid row-4">
                    <div
                        className="custom-heading text-center" data-aos="fade-up" data-aos-delay="300"
                    >
                        Choose the Best Plan
                    </div>
                    <div className="pricing-tabs row-4">
                        <div className="tabs" data-aos="fade-up" data-aos-delay="300">
                            {Object.keys(plans).map((tab) => (
                                <button
                                    key={tab}
                                    className={`tab ${activeTab === tab ? "active" : ""}`}
                                    onClick={() => switchTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                        <div className="tabs vsegseg" style={{width:"fit-content"}} data-aos="fade-up" data-aos-delay="300">
                            <button onClick={step1Tab} className={`tab ${activeStep === "step1box" ? "active" : ""}`}>Step 1</button>
                            <button onClick={step2Tab} className={`tab ${activeStep === "step2box" ? "active" : ""}`}>Step 2</button>
                        </div>

                        <div className={`cards vsbsrbs ${activeStep}`} ref={cardsRef} data-aos="fade-up" data-aos-delay="300">
                            {plans[activeTab].map((plan) => (
                                <div
                                    className="card"
                                    key={plan.type}
                                >
                                    <div className="evaluation-card ec-card row-2">
                                        <h2 className="card-title">{plan.type}</h2>
                                        <h3 className="card-subtitle">
                                            {plan.price}
                                        </h3>
                                        <p className="card-description">Simple and powerful</p>
                                        <button className="choose-plan">
                                            Choose {plan.type} Plan <Icon.ArrowrightSvg />
                                        </button>
                                        <div className="details">
                                            <h4 className="details-title">Get Started Today:</h4>
                                            <p>
                                                Challenge fees: <span>{plan.details.challengePhase}</span>
                                            </p>
                                            <p>
                                                Challenge Type: <span>{plan.details.ChallengeType}</span>
                                            </p>
                                            <p>
                                                Profit Target: <span>{plan.details.ProfitTarget}</span>
                                            </p>
                                            <p>
                                                Maximum Daily Loss: <span>{plan.details.MaximumDailyLoss}</span>
                                            </p>
                                            <p>
                                                Maximum Overall Loss: <span>{plan.details.MaximumOverallLoss}</span>
                                            </p>
                                            <p>
                                                Minimum Trading Days: <span>{plan.details.MinimumTrading}</span>
                                            </p>
                                            <p>
                                                Profit Split Upto: <span>{plan.details.ProfitSplitUpto}</span>
                                            </p>
                                            <p>
                                                News Trading: <span>{plan.details.NewsTrading}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pricetable;
