import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import Icon from '../../components/Icon';
import "../../css/footer.css";

const Footer = () => {
    return (
        <>
            <footer className="footer p-100">
                <div className='container'>
                    <div className="footer-top">
                        <div className="footer-logo row-4">
                             <img src={Img.logo} alt='logo' />
                           
                            <p className='small'>© 2024 All rights reserved</p>
                        </div>
                        <div className="footer-links">
                            <div className="footer-column">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><Link to='/'>Home</Link></li>
                                    <li><Link to='/faq'>FAQ</Link></li>
                                    <li><Link to='/trading-rules'>Trading Rules</Link></li>
                                    <li><Link to='/affiliate-program'>Affiliate Program</Link></li>
                                    <li><Link to='/blog'>Blog</Link></li>
                                    <li><Link to='/contact'>Contact Us</Link></li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <h4>LEGAL</h4>
                                <ul>
                                    <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                    <li><Link to='/terms-conditions'>Terms & Conditions</Link></li>
                                    <li><Link to='/cookie-policy'>Cookie Policy</Link></li>
                                    <li><Link to='/aml-policy'>AML Policy</Link></li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <h4>CONTACT US</h4>
                                <p>Be the first to know about promotions and discount</p>
                                <ul>
                                    <li><Link to="mailto:support@fundedfirm.com"><i className="fa fa-envelope"></i>  Email</Link></li>
                                    <li><Link to="https://wa.me/message/KL5HIQ3XMD65P1" target="_blank"><i className="fab fa-whatsapp"></i>  Whatsapp</Link></li>
                                </ul>
                                <div className="newsletter-input">
                                    <input type="email" placeholder="Enter your email" />
                                    <button><Icon.ArrowrightSvg /></button>
                                </div>
                                {/* <div className="social-icons">
                                    <Link to='/'><i className="fab fa-facebook-f"></i></Link>
                                    <Link to='/'><i className="fab fa-youtube"></i></Link>
                                    <Link to='/'><i className="fab fa-linkedin-in"></i></Link>
                                    <Link to='/'><i className="fab fa-twitter"></i></Link>
                                    <Link to='/'><i className="fab fa-instagram"></i></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-column">
                            <h4>Risk Management</h4>
                            <p>
                             Ensure you manage your risk by never exceeding the set drawdown limits. Protect your capital and trade with discipline to preserve long-term profitability.
                            </p>
                        </div>
                        <div className="footer-column">
                            <h4> Consistent Profitability</h4>
                            <p>
                                Achieve consistent profitability by adhering to your trading plan. Fundedfirms typically require a minimum monthly profit target to maintain funding and access to capital.
                            </p>
                        </div>
                        <div className="footer-column">
                            <h4>Trading Strategy Compliance</h4>
                            <p>
                                Follow the firm's guidelines regarding trading strategies, including asset types and trade volume. Staying compliant ensures you remain within the terms of the agreement and avoid penalties.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer