import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import * as Img from '../../components/Img';
import Icon from '../../components/Icon';

const Banifit = () => {
  
    return (
        <div className="benifit-section p-80 overflowhidden">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-right">
                        <div className="oat-box row-3">
                            <div className="buttons" >
                                <Link to="/" className="btn-primary">
                                    Why FundedFirm
                                </Link>
                            </div>
                            <div className="custom-heading ch-medium" >
                                <span>Trade with the Most</span> Trusted Prop Firm
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 offset-lg-2 row-3" data-aos="fade-left">
                        <div className="custom-heading ch-xssmall">
                            Unlock your trading potential with a firm that prioritizes transparency, fast payouts, and top-tier support. Join thousands of successful traders on their journey to financial freedom. Your success is our mission!
                        </div>
                        <div className="buttons" >
                            <Link to="/get-funded" className="btn-primary">
                                Get Funded <span><Icon.ArrowrightSvg /></span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="custom-evaluation-box row-3">
                    <div className="row">
                        <div className="col-md-6" data-aos="fade-up">
                            <div className="evaluation-card ec-card">
                                <img src={Img.Boxleft} className="left-s" />
                                <img src={Img.Boxright} className="right-s" />
                                <div className="card-header">
                                    <span className="card-index">0.1</span>
                                    <div className="icon-container">
                                        <div className="icon-bg">
                                            <img src={Img.verified2} alt="Calendar Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-content row-2">
                                    <h3 className="card-title">Guaranteed Payouts</h3>
                                    <h4 className="card-subtitle">No max or min evaluation days</h4>
                                    <p className="card-description">
                                        Receive your payment within 24 hours.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="fade-up">
                            <div className="evaluation-card ec-card">
                                <img src={Img.Boxleft} className="left-s" />
                                <img src={Img.Boxright} className="right-s" />
                                <div className="card-header">
                                    <span className="card-index">0.2</span>
                                    <div className="icon-container">
                                        <div className="icon-bg">
                                            <img style={{padding: "10px"}} src={Img.Leptop2} alt="Calendar Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-content row-2">
                                    <h3 className="card-title">Trade Smarter on MT5</h3>
                                    <h4 className="card-subtitle">Best Trading Platform Trade on MT5 platform</h4>
                                    <p className="card-description">
                                        Trade on MT5 platform Best Trading Platforms Trade on MT5 Platform FundedFirm traders can choose to trade MetaTrader 5 platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6" data-aos="fade-up">
                            <div className="evaluation-card ec-card" >
                                <img src={Img.Boxleft} className="left-s" />
                                <img src={Img.Boxright} className="right-s" />
                                <div className="card-header">
                                    <span className="card-index">0.3</span>
                                    <div className="icon-container">
                                        <div className="icon-bg">
                                            <img src={Img.Sathi2} alt="Calendar Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-content row-2">
                                    <h3 className="card-title">Trade with the Best Conditions</h3>
                                    <h4 className="card-subtitle">Best Trading Conditions</h4>
                                    <p className="card-description">
                                        Enjoy tight spreads, low commissions, and high leverage, providing you with the best possible conditions to maximize your trading opportunities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4" >
                            <div className="evaluation-card ec-card">
                                <img src={Img.Boxleft} className="left-s" />
                                <img src={Img.Boxright} className="right-s"/>
                                <div className="card-header">
                                    <span className="card-index">0.5</span>
                                    <div className="icon-container">
                                        <div className="icon-bg">
                                            <img src={Img.World} alt="Calendar Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-content row-2">
                                    <h3 className="card-title">FundedFirm Community</h3>
                                    <h4 className="card-subtitle">Serving over 750k + community</h4>
                                    <p className="card-description">
                                        Join a thriving community of over 750,000 traders and investors who trust our platform for their financial growth. With access to exclusive tools.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6" data-aos="fade-up">
                            <div className="evaluation-card ec-card">
                                <img src={Img.Boxleft} className="left-s"/>
                                <img src={Img.Boxright} className="right-s"/>
                                <div className="card-header">
                                    <span className="card-index">0.4</span>
                                    <div className="icon-container">
                                        <div className="icon-bg">
                                            <img src={Img.watch2} alt="Calendar Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-content row-2">
                                    <h3 className="card-title">Time to Join</h3>
                                    <h4 className="card-subtitle">Time to Start trading now</h4>
                                    <p className="card-description">
                                        It’s time to take action. Start trading today and unlock your potential with the best trading conditions available. FundedFirm is your key to success.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banifit;
