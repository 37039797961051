import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import Icon from '../../components/Icon';

const Customersupport = () => {

    return (
        <>
            <div className='customer-support p-100' style={{
                backgroundImage: `url(${Img.Customersupport})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className='container-fluid'>
                    <div className="community-section" >
                        <div className="container-fluid row-3 "  data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
                            <div className='custom-heading ch-medium text-center'>
                                Join Our
                                <div className="logo-circle">
                                    <div className="logo-icon">
                                        <img style={{width:"100%",padding: "2px"}} src={Img.lvsfbsogo} alt='' />
                                    </div>
                                </div>
                                Community
                            </div>
                            <div className='custom-heading ch-xssmall text-center'>
                                Join our community of passionate traders and take your skills to the next level. Be part of a supportive network today!
                            </div>

                            {/* Stats */}
                            {/* <div className="stats">
                                <div className="stat-item">
                                    <h2 className="stat-value">$9.7M+</h2>
                                    <p className="stat-label">Total Payouts</p>
                                </div>
                                <div className="stat-item">
                                    <h2 className="stat-value">99k+</h2>
                                    <p className="stat-label">Traders</p>
                                </div>
                                <div className="stat-item">
                                    <h2 className="stat-value">6 hrs</h2>
                                    <p className="stat-label">Average time</p>
                                </div>
                            </div> */}

                            {/* Button */}
                            <div className="buttons">
                                <Link to="https://my.fundedfirm.com/register" target='_blank' className="btn-primary">
                                    Join <b>FUNDEDFIRM</b>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='container mt-5'>
                        <div className='lets-talk' data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
                            <div className='lt-heading text-center'>
                            Let's Talk Trading
                            </div>
                            <div className='lt-inner' >
                                <img src={Img.letstalk} alt='letstalk'/>
                                <div className='lt-inner-bx row-3'>
                                    <div className='custom-heading ch-medium text-center'>
                                        Our Capital, Your Profits
                                    </div>
                                    <div className='custom-heading ch-xssmall text-center'>
                                        Get funded today and start achieving your financial goals.
                                    </div>
                                    {/* Button */}
                                    <div className="buttons">
                                        <Link to="https://my.fundedfirm.com/register" target='_blank' className="btn-primary">
                                            Join <b>FUNDEDFIRM</b>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Customersupport