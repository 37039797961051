import React, { useState } from 'react';
import * as Img from '../../components/Img'

const Tradingrules = () => {

    const [activeTab, setActiveTab] = useState('Step 1');
    return (
        <>
            <section className="pb-5 main-section-one">
                <div className="container">
                    <div className="row align-items-center sec-enabling-top">
                        <div className="col-lg-7 col-md-12 order-lg-1 mt-4 order-2">

                            <div className="common-heading-bx center-head-sub">
                                <div className="common-heading ch-32 ch-color-gradient mx-auto mx-md-0">Trade without any boundaries</div>
                                <div className="common-sub-heading">At FundedFirm, our trading rules are designed to foster a disciplined and successful trading environment. </div>
                                <div className="common-sub-heading">Our rules are crafted to encourage prudent trading practices, including risk management and adherence to strategic goals. By aligning our rules with best practices in the industry, FundedFirm ensures that traders operate within a framework that promotes stability and growth.</div>
                            </div>

                            {/* <div className="d-flex justify-content-md-center justify-content-lg-start justify-content-center flex-wrap cbi-gap-10px mt-5">
                                <div className="common-btn-item cbi-enquiry" type="button" data-toggle="modal" data-target="#inquiryModal"><span>Enquiry</span></div>
                            </div> */}

                        </div>
                        <div className="col-lg-5 col-md-12 order-lg-2 order-1">
                            <img src={Img.Trader} className="common-side-img-tr mx-auto csi-main-img vgsrhrh" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <img className="main-section-img" src={Img.Tradingbg} alt="Tradingbg" />
            </section>

            <section className="main-tab mt-270">
                <div className="container">

                    <div className="common-heading-bx mb-5">
                        <div className="common-heading ch-color-gradient text-center mx-auto" style={{ whiteSpace: 'nowrap' }}>Trading Rules</div>
                    </div>

                    <div className="plans-step-btns-bx">
                        <div
                            className={`psb-item cdv ${activeTab === 'Step 1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Step 1')}
                        >
                            Step 1
                        </div>
                        <div
                            className={`psb-item cdv ${activeTab === 'Step 2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Step 2')}
                        >
                            Step 2
                        </div>
                    </div>


                    {activeTab === 'Step 1' &&
                        <div className="psb-content" id="psb2">
                            <section className="pb-5 relative pt-3">
                                <div className="common-heading-bx">
                                    <div className="common-heading ch-color-gradient">Step 1 Rules</div>
                                </div>

                                <div className="pb-3 mt-4">
                                    <div className="common-heading-bx">
                                        <div className="main-heading pl-0 ml-0">We Know You can Make Profits :</div>
                                        <p className="common-sub-content">Traders must achieve a minimum profit of 10% on their initial trading account balance within unlimited trading days. Traders, therefore, need to hit at least 10% profit on their starting capital to keep within the Rule.</p>

                                        <div className="main-heading pl-0 ml-0">Risk Management is Must :</div>
                                        <p className="common-sub-content">In order to better provide you with products and services offered, FundedFirm may collect personally identifiable information, such as your:</p>

                                        <ul className="mb-0">
                                            <li>Maximum Daily Drawdown - 3%</li>
                                            <li>Maximum Overall Drawdown -6%</li>
                                        </ul>

                                        <p className="common-sub-content">The Trading Period is Unlimited and if the Account is Inactive for 30 days it will be Disabled.</p>

                                        <div className="main-heading pl-0 ml-0">Leverage is Important :</div>
                                        <p className="common-sub-content">Because We want You to Trade Tension Free and Don’t Bother
                                            About Leverage, So We have this for You.</p>

                                        <ul className="mb-0">
                                            <li>Forex: 1:100</li>
                                            <li>Metals: 1:100</li>
                                            <li>Indices: 1:100</li>
                                            <li>Energies: 1:100</li>
                                            <li>Crypto: 1:50</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">Weekend and Overnight Holding :</div>
                                        <p className="common-sub-content">We Want You to make Money , Hold Till Whenever You Wish,
                                            We Don’t Mind. </p>

                                        <div className="main-heading pl-0 ml-0">News Trading Allowed :</div>
                                        <p className="common-sub-content">News trading is Allowed , Do Fundamental analysis and Trade the
                                            way You want.</p>

                                        <div className="main-heading pl-0 ml-0">Hedging Rule :</div>

                                        <ul className="mb-0">
                                            <li>You cannot open buy-and-sell positions in the same currency
                                                pair.</li>
                                            <li>For example, At 1940-XAUUSD Buy, At1960-XAUSD Sell.</li>
                                            <li>You cannot have both a buy position and a sell position open
                                                at the same time.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">Profit Sharing :</div>

                                        <ul className="mb-0">
                                            <li>1st month - 90% Payout of Profit will Be Given</li>
                                            <li>2nd month - 95% Payout of Profit will Be Given</li>
                                            <li>3rd month - 100% Payout of Profit will Be Given</li>
                                        </ul>

                                        <p className="common-sub-content pt-3">*A profit of Minimum 1% of the account Size is important to apply
                                            for a Payout request.</p>

                                        <div className="main-heading pl-0 ml-0">Consistency Rules</div>
                                        <p className="common-sub-content">We FundedFirm Ensure that traders maintain consistent trading volumes and activity levels,
                                            promoting disciplined trading behavior and effective risk management.</p>

                                        <div className="main-heading pl-0 ml-0">Rule Outline :</div>


                                        <div className="main-heading pl-0 ml-0">1. Minimum Trading days :</div>
                                        <ul>
                                            <li>The trader must trade consistently over a minimum of <span className="boldtext">5 Trading Days </span>within a calendar month to qualify for evaluation.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">2. Risk Management & Discipline :</div>
                                        <ul>
                                            <li>A trader should aim to ensure that no single trade accounts for more than <span className="boldtext">30%</span> of their total profits, as achieving balanced profits across multiple trades demonstrates consistency and skill, rather than relying on luck for success.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">2(1). Violation Consequences :</div>
                                        <ul>
                                            <li>Any breach of the established trading rules will lead to an immediate account suspension and permanent disablement.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">Rationale :</div>
                                        <p className="common-sub-content">This rule encourages traders to maintain steady and predictable trading volumes, which helps in managing risk and ensuring that profits are earned through consistent trading behavior. It also offers room for growth, allowing traders to scale their volumes over time while maintaining discipline.</p>

                                        <div className="main-heading pl-0 ml-0">Please note :</div>
                                        <p className="common-sub-content">Hitting either the daily loss limit or the maximum loss limit will result in a permanent account breach.</p>

                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                    {activeTab === 'Step 2' &&
                        <div className="psb-content" id="psb3">
                            <section className="pb-5 relative pt-3">
                                <div className="common-heading-bx">
                                    <div className="common-heading ch-color-gradient">Step 2 Rules</div>
                                </div>


                                <div className="pb-3 mt-4">
                                    <div className="common-heading-bx">
                                        <div className="main-heading pl-0 ml-0">We Know You can Make Profits :</div>
                                        <p className="common-sub-content">Traders must achieve a minimum profit of 8% on their initial account balance in Phase 1 with an unlimited trading period & 5% profit target in phase two on their starting capital. The trading period is unlimited, but if the account stays inactive for 30 days, it will be disabled.</p>

                                        <div className="main-heading pl-0 ml-0">Risk Management is Must :</div>
                                        <p className="common-sub-content">In order to better provide you with products and services offered, FundedFirm may collect personally identifiable information, such as your:</p>

                                        <ul className="mb-0">
                                            <li>Maximum Daily Drawdown - 5%</li>
                                            <li>Maximum Overall Drawdown -10%</li>
                                        </ul>

                                        <p className="common-sub-content">The Trading Period is Unlimited and if the Account is Inactive for 30 days it will be Disabled.</p>

                                        <div className="main-heading pl-0 ml-0">Leverage is Important :</div>
                                        <p className="common-sub-content">Because We want You to Trade Tension Free and Don’t Bother
                                            About Leverage, So We have this for You.</p>

                                        <ul className="mb-0">
                                            <li>Forex: 1:100</li>
                                            <li>Metals: 1:100</li>
                                            <li>Indices: 1:100</li>
                                            <li>Energies: 1:100</li>
                                            <li>Crypto: 1:50</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">Weekend and Overnight Holding :</div>
                                        <p className="common-sub-content">We Want You to make Money , Hold Till Whenever You Wish,
                                            We Don’t Mind.</p>

                                        <div className="main-heading pl-0 ml-0">News Trading Allowed :</div>
                                        <p className="common-sub-content">News trading is Allowed , Do Fundamental analysis and Trade the
                                            way You want.</p>

                                        <div className="main-heading pl-0 ml-0">Hedging Rule :</div>

                                        <ul className="mb-0">
                                            <li>You cannot open buy-and-sell positions in the same currency
                                                pair.</li>
                                            <li>For example, At 1940-XAUUSD Buy, At1960-XAUSD Sell.</li>
                                            <li>You cannot have both a buy position and a sell position open
                                                at the same time.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">Profit Sharing :</div>

                                        <ul className="mb-0 ">
                                            <li>1st month - 90% Payout of Profit will Be Given</li>
                                            <li>2nd month - 95% Payout of Profit will Be Given</li>
                                            <li>3rd month - 100% Payout of Profit will Be Given</li>
                                        </ul>

                                        <p className="common-sub-content pt-3">*A profit of Minimum 1% of the account Size is important to apply
                                            for a Payout request.</p>

                                        <div className="main-heading pl-0 ml-0">Consistency Rules</div>
                                        <p className="common-sub-content">We FundedFirm Ensure that traders maintain consistent trading volumes and activity levels,
                                            promoting disciplined trading behavior and effective risk management.</p>

                                        <div className="main-heading pl-0 ml-0">Rule Outline :</div>

                                        <div className="main-heading pl-0 ml-0">1. Minimum Trading days :</div>
                                        <ul>
                                            <li>The trader must trade consistently over a minimum of <span className="boldtext">5 Trading Days </span>within a calendar month to qualify for evaluation.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">2. Risk Management & Discipline : </div>
                                        <ul>
                                            <li>A trader should aim to ensure that no single trade accounts for more than <span className="boldtext">30%</span> of their total profits, as achieving balanced profits across multiple trades demonstrates consistency and skill, rather than relying on luck for success.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">2(1). Violation Consequences :</div>
                                        <ul>
                                            <li>Any breach of the established trading rules will lead to an immediate account suspension and permanent disablement.</li>
                                        </ul>

                                        <div className="main-heading pl-0 ml-0">Rationale :</div>
                                        <p className="common-sub-content">This rule encourages traders to maintain steady and predictable trading volumes, which helps in managing risk and ensuring that profits are earned through consistent trading behavior. It also offers room for growth, allowing traders to scale their volumes over time while maintaining discipline.</p>

                                        <div className="main-heading pl-0 ml-0">Please note :</div>
                                        <p className="common-sub-content">Hitting either the daily loss limit or the maximum loss limit will result in a permanent account breach.</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}

export default Tradingrules