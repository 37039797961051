import React, { useState, useEffect } from 'react';

// Images Common File
import * as Img from '../components/Img';

// Css File
import "../css/loader.css";

function Loader() {
    const [addDeactivate, setDeactivateClass] = useState(false);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setDeactivateClass(true);
      }, 1000);
  
      return () => clearTimeout(timeout);
    }, []);
    
    return (
        <div className={`web-preloader ${addDeactivate ? 'deactivate' : ''}`}>
            <span className="web-inner">
                <img src={Img.icon2} alt="" />
            </span>
        </div>
    );
}

export default Loader;