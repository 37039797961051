import React from 'react'
import { Link } from 'react-router-dom';
import * as Img from '../../components/Img'

const Blogdetail = () => {
    return (
        <>
            <section className="relative pt-4">
                <div className="container cl-custome">
                    <div className="textlayout-main-bx">
                        <div className="breadcrumb-bx">
                            <Link className="breadcrumb-link" to="/">Home</Link>
                            <Link className="breadcrumb-link details-link" to="/blog">/ Blog</Link>
                            <div className="breadcrumb-link breadcrumb-active">/ Details</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container pb-3">
                    <div className="row">
                        <div className="common-heading-bx mx-auto">
                            <p className="common-heading ch-color-gradient mont-font mx-auto common-details-title">Maximize Your Trading Potential: How FundedFirm’s Capital Can Propel Your Forex Career</p>
                        </div>

                        <div className="col-md-12 mx-auto">
                            <div className="blog-item our-work-item wow fadeInUp animated">

                                <div className="blog-imgs">
                                    <img style={{padding:0}} src={Img.Blogthumb5} alt="" />
                                </div>

                                <div className="common-sub-content mt-4">
                                    <p>Remember when a gold star on your homework felt like the pinnacle of achievement? At FundedFirm, we’ve taken that concept and amplified it to a whole new level with our performance-based rewards. Think of it as trading with an ongoing reward system that celebrates your success and motivates you to reach new heights.</p>
                                    <p>Our performance-based model is designed to align your rewards with your trading achievements. It’s not just about hitting targets; it’s about recognizing and celebrating your hard work and skill. The better you perform, the more rewarding your trading experience becomes. It’s like having a constant pat on the back—and who doesn’t love that?</p>
                                    <p>At FundedFirm, we believe that exceptional traders deserve exceptional rewards. Our system ensures that your efforts are directly linked to tangible benefits. This means you can see the fruits of your labor in real-time and stay motivated to keep pushing the boundaries of your trading capabilities.</p>
                                    <p>But our rewards are not just about financial gains; they’re also about acknowledging your dedication and growth as a trader. We’re here to support you every step of the way and provide the incentives that keep you striving for excellence.</p>
                                    <p>So, if you’re ready to trade in an environment where your performance is celebrated and rewarded, FundedFirm is the place for you. Join us and experience a trading journey where success is recognized and incentivized, making every achievement feel like a well-deserved victory.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blogdetail