import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom'

const Faqs = () => {
    const [openIndex, setOpenIndex] = useState({}); // Tracks the currently open accordion in each section
    const refs = useRef({}); // Store refs for each accordion panel

    const data = [
        {
            section: 'General Information',
            questions: [
                { title: 'What is Fundedfirm?', content: 'Fundedfirm is a Forex proprietary trading firm that provides capital to traders, allowing them to trade without risking their own money. Traders who meet specific criteria can manage large accounts and share in the profits.' },
                { title: 'How does Fundedfirm’s funded account program work?', content: 'Fundedfirm is a Forex proprietary trading firm that provides capital to traders, allowing them to trade without risking their own money. Traders who meet specific criteria can manage large accounts and share in the profits.' },
                { title: 'What are the benefits of trading with Fundedfirm?', content: 'Fundedfirm offers the opportunity to trade with substantial capital, access to advanced trading platforms, and a profit-sharing model. This allows traders to scale their trading without risking their own funds.' },
                { title: 'What markets can I trade with Fundedfirm?', content: 'Traders can access various markets, including Forex, commodities, indices, and cryptocurrencies, depending on the firm s offerings.' },
                { title: 'What is the profit split with Fundedfirm?', content: 'Fundedfirm typically offers a profit split, where a percentage of the profits generated by the trader is paid to them. The exact split may vary depending on the account type and performance.' },
                { title: 'How do I apply for a funded account with Fundedfirm?', content: 'To apply for a funded account, visit Fundedfirms website, select the desired account size, and complete the application process, including any evaluation phases.' },
                { title: 'What are the evaluation phases for a funded account?', content: 'Fundedfirm typically has one or more evaluation phases where traders must meet specific profit targets and adhere to risk management rules to qualify for a funded account.' },
                { title: 'Is there a fee to apply for a funded account with Fundedfirm?', content: 'Yes, there may be a fee for the evaluation process, which varies depending on the account size and type.' },
                { title: 'Can I trade during news events with Fundedfirm?', content: 'Fundedfirms policy on trading during news events may vary. Its essential to check the specific rules and guidelines provided for each account type.' },
                { title: 'What trading platforms does Fundedfirm support?', content: 'Fundedfirm supports various trading platforms, including MetaTrader 4 (MT4) and MetaTrader 5 (MT5). Check the website for the latest platform offerings.' },

            ],
        },
        {
            section: 'Account Types and Sizes',
            questions: [
                { title: 'What account sizes does Fundedfirm offer?', content: 'Fundedfirm offers various account sizes, typically ranging from $5000 to $200,000.' },
                { title: 'Can I choose the account size I want to apply for?', content: 'Yes, traders can select the account size that best suits their trading style and capital management strategy.' },
                { title: 'Are there different types of funded accounts?', content: 'Fundedfirm may offer different account types, such as 1 Step & 2 step each with unique rules and profit splits.' },
                { title: 'What are the differences between account types at Fundedfirm?', content: 'Account types may differ in terms of risk parameters, profit targets, maximum drawdown, and profit splits. Specific details are provided on Fundedfirms website.' },
                { title: 'Can I trade multiple funded accounts simultaneously?', content: 'That KYC can be done from one account it will be disabled.' },
                { title: 'What is the max number of MT5 accounts that clients can use from one ID?', content: 'One trader is allowed to take only one funded account.' },
                { title: 'What is the starting balance for a funded account?', content: 'The starting balance varies depending on the account size chosen during the application process.' },
                { title: 'Do funded accounts have a leverage limit?', content: 'Yes, Fundedfirm typically sets leverage limits for funded accounts. The specific leverage offered may depend on the account type and trading conditions.' },
                { title: 'Can I upgrade my funded account size?', content: 'Fundedfirm may offer opportunities to upgrade account sizes based on performance and other criteria.' },
                { title: 'What happens if I exceed the maximum drawdown limit?', content: 'Exceeding the maximum drawdown limit typically results in the termination of the funded account. Specific consequences may vary by account type.' },
            ],
        },
        {
            section: 'Trading Rules and Guidelines',
            questions: [
                { title: 'What are the trading rules for a funded account at Fundedfirm?', content: 'Fundedfirm has specific trading rules that must be followed, including risk management guidelines, maximum drawdown limits, and other restrictions.' },
                { title: 'Is there a minimum trading volume requirement?', content: 'No.' },
                { title: 'Can I trade over the weekend with a funded account?', content: 'Trading over the weekend may be allowed or restricted depending on the account type and market conditions.' },
                { title: 'Are there restrictions on the types of trading strategies I can use?', content: 'Fundedfirm may have restrictions on certain strategies, such as scalping, hedging, or highfrequency trading. Review the accounts specific guidelines.' },
                {
                    title: 'What is the maximum allowable drawdown?',
                    content: (
                        <>
                            <p>The maximum allowable drawdown varies by account type and is specified in the trading rules. Exceeding this limit may result in account termination.</p>
                            <p>We offer balance-based trailing drawdown</p>
                            <p>Drawdown Rules Explanation:</p>
                            <p>Initial Account Balance: $100,000</p>
                            <ul>
                                <li>Daily Drawdown Limit: 3% of $100,000 = $3,000</li>
                                <li>After Profit: If the account balance increases to $110,000</li>
                            </ul>
                            <p>(e.g., a profit of $10,000), the drawdown limits adjust accordingly:</p>
                            <ul>
                                <li>New Daily Drawdown Limit: 3% of $110,000 = $3,300</li>
                                <li>Overall Drawdown Limit: 6% of $110,000 = $6,600</li>
                            </ul>
                            <p>
                                These rules ensure that both the daily and overall drawdown limits are calculated based on
                                the current account balance, including any profits made. This approach promotes disciplined
                                risk management while allowing flexibility as your account grows.
                            </p>
                        </>
                    ),
                },

                {
                    title: 'Are there daily loss limits for funded accounts?',
                    content: (
                        <>
                            <p>Some accounts may have daily loss limits to manage risk. Check the specific account rules for details.</p>
                            <p>We offer balance based trailing drawdown</p>
                            <p>Drawdown Rules Explanation:</p>
                            <p>Initial Account Balance: $100,000</p>
                            <ul>
                                <li>Daily Drawdown Limit: 3% of $100,000 = $3,000</li>
                                <li>After Profit: If the account balance increases to $110,000</li>
                            </ul>
                            <p>(e.g., a profit of $10,000), the drawdown limits adjust accordingly:</p>
                            <ul>
                                <li>New Daily Drawdown Limit: 3% of $110,000 = $3,300</li>
                                <li>Overall Drawdown Limit: 6% of $110,000 = $6,600</li>
                            </ul>
                            <p>
                            These rules ensure that both the daily and overall drawdown limits are calculated based on the current account balance, including any profits made. This approach promotes disciplined risk management while allowing flexibility as your account grows.
                            </p>
                        </>
                    ),
                },
                { title: 'Can I use Expert Advisors (EAs) on my funded account?', content: 'The use of Expert Advisors is not allowed on Fundedfirm.' },
                { title: 'Is there a maximum lot size I can trade?', content: 'No, but trader should consistent with lot sizes.' },
                { title: 'Can I hold positions overnight with a funded account?', content: 'Holding positions overnight may be allowed or restricted based on the accounts specific rules and market conditions.' },
                { title: 'What happens if I violate the trading rules?', content: 'Violating trading rules may result in penalties, including the termination of the funded account. Its essential to understand and adhere to the rules.' },    

                
            ],
        },

        {
            section: 'Profit Sharing and Payouts',
            questions: [
                { title: 'How does profit sharing work at Fundedfirm?', content: 'Profit sharing involves distributing profits generated by the trader between the trader and Fundedfirm. The exact profit split ratio depends on the account type and performance.' },
                { title: 'What is the typical profit split percentage?', content: 'The profit split percentage varies but is often around 70%-100 % in favor of the trader. Specific details are provided for each account type.' },
                { title: 'When are payouts made to traders?', content: 'Payouts are typically made on a monthly basis, but the exact schedule may vary depending on Fundedfirms policies.' },
                { title: 'How can I request a payout from my funded account?', content: 'Payout requests can usually be made through Fundedfirms client portal. The process and requirements are outlined in the account terms.' },
                { title: 'Are there any fees associated with payouts?', content: 'No hidden fees is Charged by Fundedfirm.' },
                { title: 'Can I reinvest my profits into my funded account?', content: 'Reinvestment options may be available, allowing traders to increase their account size or trading capital.' },
                { title: 'What payment methods are available for payouts?', content: 'Fundedfirm may offer various payment methods, including UPI, bank transfers, cryptocurrency.' },
                { title: 'Are there any taxes on my profits?', content: 'Traders are responsible for reporting and paying taxes on their profits according to their local tax laws.' },
                { title: 'Can I withdraw profits at any time?', content: 'Payout schedules and withdrawal conditions are specified in the account terms.' },
                { title: 'What happens to my profits if my funded account is terminated?', content: 'Profits earned prior to termination may be paid out according to Fundedfirms policies, but any ongoing trades or violations may impact the final amount.' },

            ],
        },

        {
            section: 'Application and Evaluation Process',
            questions: [
                { title: 'How do I start the application process for a funded account?', content: 'Begin by visiting Fundedfirms website, selecting an account type, and completing the registration and evaluation process.' },
                { title: 'What is the evaluation process for a funded account?', content: 'The evaluation process typically involves meeting specific profit targets and adhering to risk management rules over a set period.' },
                { title: 'Is there a time limit for completing the evaluation phase?', content: 'Yes, there is usually a time limit for completing the evaluation phase. The exact duration depends on the account type.' },
                { title: 'Can I retry the evaluation if I fail?', content: 'Fundedfirm may offer the option to retry the evaluation.' },
                { title: 'Are there any prerequisites for applying for a funded account?', content: 'No prerequisites , anyone can apply for fundedfirm account.' },
                { title: 'How long does it take to receive a funded account after passing the evaluation?', content: 'The time frame for receiving a funded account varies but is typically within a few business days after completing the evaluation.' },
                { title: 'Can I apply for multiple funded accounts at once?', content: 'Fundedfirms policy on multiple applications is Strict so one can apply for only one account at a time.' },
                { title: 'Is there an age requirement to apply for a funded account?', content: 'Yes, applicants must typically be at least 18 years old or meet the legal age requirements in their jurisdiction.' },
                { title: 'What documents are required to apply for a funded account?', content: 'Required documents may include identification cards.' },
                { title: 'Can I trade during the evaluation phase?', content: 'Yes, trading during the evaluation phase is necessary to meet the profit and risk management criteria.' },

            ],
        },

        {
            section: 'Trading Platforms and Tools',
            questions: [
                { title: 'What trading platforms are supported by Fundedfirm?', content: 'Fundedfirm supports popular trading platform MetaTrader 5 (MT5).' },
                { title: 'Can I use my own trading platform or tools?', content: 'Traders must use the platforms and tools supported by Fundedfirm. Custom tools may be restricted.' },
                { title: 'Are there any platform fees for using Fundedfirms trading platforms?', content: 'Platform fees are covered by Fundedfirm, Traders don’t need to worry.' },
                { title: 'Does Fundedfirm provide trading tools and resources?', content: 'Fundedfirm may offer various trading tools, resources, and educational materials to support traders.' },
                { title: 'Can I access trading signals through Fundedfirm?', content: 'Trading signals may be available, depending on the account type and services offered.' },
                { title: 'Is mobile trading available with Fundedfirms platforms?', content: 'Yes, mobile trading is typically supported through apps like MT5.' },
                { title: 'Can I trade directly from the charts on Fundedfirms platforms?', content: 'Yes, trading directly from the charts is possible on supported platforms MT5.' },
                { title: 'Are there any restrictions on the use of trading robots or algorithms?', content: 'The use of trading robots or algorithms is restricted without permission.' },
                { title: 'Does Fundedfirm offer a demo account?', content: 'Yes, Fundedfirm may offer demo accounts for practice and evaluation.' },
                { title: 'Can I customize the trading platforms interface?', content: 'Customization options vary by platform, but most supported platforms allow for interface customization.' },

            ],
        },

        {
            section: 'Risk Management and Security',
            questions: [
                { title: 'How does Fundedfirm manage risk?', content: 'Fundedfirm implements strict risk management rules, including maximum drawdown limits, daily loss limits, and other safeguards.' },
                { title: 'What is the maximum drawdown allowed on funded accounts?', content: 'The maximum drawdown limit varies by account type and is specified in the trading rules.' },
                { title: 'Are there stop-out levels on funded accounts?', content: 'Stop-out levels may be in place to automatically close positions if losses reach a certain threshold.' },
                { title: 'Can I use a stop-loss order on my trades?', content: 'Yes, using stop-loss orders is encouraged to manage risk.' },
                { title: 'Does Fundedfirm offer negative balance protection?', content: 'Negative balance protection may be provided, ensuring traders do not owe more than their account balance.' },
                { title: 'How does Fundedfirm protect traders personal information?', content: 'Fundedfirm employs robust security measures to protect personal and financial information.' },
                { title: 'Are my funds safe with Fundedfirm?', content: 'Fundedfirm does not hold personal funds, as it provides capital for trading. Traders profits are paid out according to the agreement.' },
                { title: 'Can I set my own risk management rules?', content: 'Traders must adhere to Fundedfirms risk management rules, but they can set additional personal risk parameters.' },
                { title: 'Is there insurance on funded accounts?', content: 'Funded accounts are typically not insured, as they involve trading with the firms capital.' },
                
            ],
        },

        {
            section: 'Fees and Charges',
            questions: [
                { title: 'What are the fees for applying for a funded account?', content: 'The evaluation fee varies depending on the account size and type. Check Fundedfirms website for specific pricing.' },
                { title: 'Are there any monthly fees for funded accounts?', content: 'No.' },
                { title: 'Is there a fee for using trading platforms?', content: 'Platform fees may be covered by Fundedfirm' },
                { title: 'Are there any hidden fees associated with funded accounts?', content: 'Fundedfirm aims to be transparent about all fees. Review the terms and conditions for complete details.' },
                { title: 'Do I need to pay for data feeds?', content: 'Data feed costs may be covered by Fundedfirm' },
                { title: 'Are there any withdrawal fees?', content: 'Withdrawal fees may apply, depending on the payment method and account terms.' },
                { title: 'Is there a fee for failing the evaluation phase?', content: 'The evaluation fee is typically non-refundable, even if the evaluation phase is not passed.' },
                { title: 'Are there any fees for inactivity?', content: 'Inactivity fees may apply if a funded account is not actively traded. Check the account terms for specifics.' },
                { title: 'What payment methods are accepted for fees?', content: 'Fundedfirm accepts various payment methods, including credit/debit cards, bank transfers, and cryptocurrencies' },
                
            ],
        },

        {
            section: 'Support and Customer Service',
            questions: [
                { title: 'How can I contact Fundedfirms customer support?', content: 'Customer support can be contacted via email, phone, or live chat on Fundedfirms website.' },
                { title: 'What are the customer support hours?', content: 'Customer support hours vary but are typically available during business hours on weekdays.' },
                { title: 'Is there a dedicated account manager for funded traders?', content: 'Fundedfirm may provide dedicated account managers for certain account types or high-volume traders.' },
                { title: 'Can I get support in different languages?', content: 'Fundedfirm may offer support in multiple languages. Check the website for available options.' },
                { title: 'Are there any prerequisites for applying for a funded account?', content: 'Issues can be escalated by contacting customer support and requesting to speak with a supervisor or manager.' },
                { title: 'Is there a knowledge base or help center available?', content: 'Fundedfirm may offer a knowledge base or help center with articles and FAQs for common questions.' },
                { title: 'Can I schedule a consultation with Fundedfirms team?', content: 'Consultations may be available for traders, depending on the account type and specific needs.' },
                { title: 'How quickly does Fundedfirm respond to support inquiries?', content: 'Response times are really quick but sometimes typically within 24 hours for standard inquiries.' },
                { title: 'Can I provide feedback or suggestions to Fundedfirm?', content: 'Feedback and suggestions can usually be submitted through the contact form or customer support channels.' },
                { title: 'Is there a community or forum for Fundedfirm traders?', content: 'Fundedfirm may offer a community or forum for traders to connect and share experiences.' },
                
            ],
        },

        {
            section: 'Performance and Evaluation Metrics',
            questions: [
                { title: 'What are the profit targets during the evaluation phase?', content: 'Profit targets vary by account type and are specified in the evaluation rules.' },
                { title: 'How is my performance evaluated?', content: 'Performance is evaluated based on profit targets, adherence to risk management rules, and overall trading behavior.' },
                { title: 'Are there specific metrics I need to meet during evaluation?', content: 'Metrics may include profit targets, maximum drawdown, minimum trading days, and other criteria.' },
                { title: 'Can I see my performance metrics in real-time?', content: 'Fundedfirm typically provides access to a dashboard or client portal where traders can view their performance metrics in real-time.' },
                { title: 'What happens if I exceed the profit target during evaluation?', content: 'Exceeding the profit target may result in faster approval for a funded account or additional rewards.' },
                { title: 'Are there any bonuses for high performance?', content: 'Fundedfirm may offer bonuses or incentives for exceptional performance, depending on the account type and evaluation phase.' },
                { title: 'How are my trades monitored?', content: 'Trades are monitored through the trading platform and client portal, with oversight from Fundedfirms risk management team.' },
                { title: 'Can I receive feedback on my trading performance?', content: 'Feedback may be available through customer support or account managers, depending on the account type.' },
                { title: 'What if I have a drawdown but still meet the profit target?', content: 'Meeting the profit target with a drawdown may still qualify for a funded account, as long as other rules are adhered to.' },
                { title: 'Can I get a detailed report of my trading performance?', content: 'Detailed performance reports may be available through the client portal or upon request.' },
                
            ],
        },

        {
            section: 'Legal and Compliance',
            questions: [
                { title: 'Is Fundedfirm regulated?', content: 'Fundedfirms regulatory status may vary based on its jurisdiction. Check the website for specific regulatory information.' },
                { title: 'Do I need to sign a contract with Fundedfirm?', content: 'Yes, traders typically sign a contract outlining the terms and conditions of the funded account.' },
                { title: 'What legal obligations do I have as a Fundedfirm trader?', content: 'Traders are obligated to adhere to the terms and conditions, trading rules, and applicable laws and regulations.' },
                { title: 'How is my data protected under Fundedfirms privacy policy?', content: 'Fundedfirms privacy policy outlines how personal and financial data is collected, used, and protected.' },
                { title: 'Can Fundedfirm terminate my account?', content: 'Fundedfirm reserves the right to terminate accounts for rule violations, inactivity, or other reasons outlined in the terms.' },
                { title: 'Are there any restrictions on international traders?', content: 'Fundedfirm may have restrictions for traders from certain countries due to legal and regulatory reasons.' },
                { title: 'Can I trade on behalf of others with my funded account?', content: 'Trading on behalf of others is typically prohibited unless specifically authorized by Fundedfirm.' },
            ],
        },

        {
            section: 'Other',
            questions: [
                {
                    title: 'How can slippage occur? "news"',
                    content: (
                        <>
                            <p>Slippage refers to the difference between the expected price of a trade and the price at which the trade is executed.</p>
                            <p>It can occur during volatile moments, for example during a high-impact news event in retrospect to the affected currency. This is something we can not control.</p>
                            <p>Manage your risk wisely if you hold trades during these moments.</p>
                        </>
                    ),
                },

                {
                    title: 'What is the IP rule?',
                    content: (
                        <>
                            <p>The region of your IP address(es) used to log into the Master account must remain consistent. If our Risk Team detects a change in the region, they may contact you to confirm this and request proof, such as an airline ticket, passport stamp, or live video of the location.</p>
                            
                            <ul>
                                <li>10.1 IP Address matching Standard Evaluation Stage and Master Stage
                                The region of your IP address(es) used to purchase the Evaluation, log in to www.fundedfirm.com, during the evaluation stage, the Master trading account should match and remain consistent. If our Risk Team detects a change in region, they may reach out to you to confirm and request proof.</li>
                                <li>10.2 IP Address Master Account
                                The region of your IP address(es) used to log into the Master account must remain consistent. If our Risk Team detects a change in region, they may reach out to you to confirm and request proof, such as a plane ticket, passport stamp, or live video from the location. This measure is in place to protect you from third-party vendors. If you anticipate traveling, kindly notify our support team beforehand to keep us informed and prevent interference from our end.</li>
                            </ul>
                        </>
                    ),
                },

                {
                    title: 'Are there any discounts for retying the FP Evaluation Challenge?',
                    content: (
                        <>
                            <p>You have the right to get a sharp discount , that is why we give the following discounts if you:</p>
                            
                            <ul>
                                <li>Fail Phase 1: Enjoy a 10% discount.</li>
                                <li>Fail Phase 2: Benefit from a 15% discount.</li>
                                <li>Fail Phase 3: Benefit from a 15% discount.</li>
                                <li>Fail Master/Funded: Receive an impressive 20% discount.</li>
                            </ul>
                            <p>Rest assured, this process is entirely automated. You will receive the corresponding discount code via email. Also, you can easily access the code on your account dashboard.</p>
                        </>
                    ),
                },

               
            ],
        },
    ];

    const toggleAccordion = (sectionIndex, questionIndex) => {
        setOpenIndex((prev) => ({
            ...prev,
            [sectionIndex]: prev[sectionIndex] === questionIndex ? null : questionIndex,
        }));
    };

    return (
        <section className="py-5 relative pt-4 main-section">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" href="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ FAQ</div>
                    </div>
                    <div className="common-heading-bx">
                        <div className="common-heading ch-color-gradient mont-font mx-auto mb-4">Frequently Asked Questions</div>
                    </div>
                </div>
            </div>

            <div className="accordion-section custom-bg">
                <div className="container row-3">
                    {data.map((section, sectionIndex) => (
                        <div key={sectionIndex} className="accordion">
                            <div className="faqs-label pt-0">{section.section}</div>
                            {section.questions.map((item, questionIndex) => (
                                <div key={questionIndex} className="accordion-item">
                                    {/* Accordion Title */}
                                    <div
                                        className="accordion-title"
                                        onClick={() => toggleAccordion(sectionIndex, questionIndex)}
                                    >
                                        <span>{item.title}</span>
                                        <span className="accordion-icon">
                                            {openIndex[sectionIndex] === questionIndex ? '-' : '+'}
                                        </span>
                                    </div>

                                    {/* Accordion Content */}
                                    <div
                                        className="accordion-content"
                                        ref={(el) => {
                                            if (!refs.current[sectionIndex]) refs.current[sectionIndex] = [];
                                            refs.current[sectionIndex][questionIndex] = el;
                                        }}
                                        style={{
                                            maxHeight:
                                                openIndex[sectionIndex] === questionIndex
                                                    ? `${refs.current[sectionIndex]?.[questionIndex]?.scrollHeight}px`
                                                    : '0',
                                            transition: 'max-height 0.3s ease-out',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {item.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Faqs;
