import React from 'react'
import * as Img from '../../components/Img'
import { Link } from 'react-router-dom'

const Contact = () => {
    return (
        <>
            <section className="pt-5 pt-md-3 pb-5 main-section">
                <div className="container">
                    <div className="textlayout-main-bx row align-items-center">
                        <div className="breadcrumb-bx">
                            <Link className="breadcrumb-link" to="/">Home</Link>
                            <div className="breadcrumb-link breadcrumb-active">/ Contact Us</div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="common-heading-bx">
                                <div className="common-heading ch-big  ch-color-gradient mx-auto text-center">Connect with FundedFirm</div>
                                <div className="common-sub-heading text-center">Simply send us your questions or concerns by filling out the contact form, or start a new ticket by clicking the help button at the bottom right. We're here to provide you with the best assistance possible.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-lg cl-custome pt-md-2 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-6 mt-3 mt-md-0 order-2 order-md-1">
                            <div className="row">
                                <div  className="col-md-12 col-sm-6">
                                    <a className="contact-info" href="tel:+447393926974">
                                        <span className="contact-icon">
                                            <img src={Img.Phone} alt="Phone" />
                                        </span>
                                        <div className="contact-text">
                                            <h6>Call Us</h6>
                                            <p>+44 7393 926974</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-12 col-sm-6">
                                    <a className="contact-info" href="mailto:support@fundedfirm.com">
                                        <span className="contact-icon">
                                            <img src={Img.Email} alt="email" />
                                        </span>
                                        <div className="contact-text">
                                            <h6>Email Us</h6>
                                            <p>support@fundedfirm.com</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="contact-info">
                                        <span className="contact-icon">
                                            <img src={Img.location} alt="location" />
                                        </span>
                                        <div className="contact-text">
                                            <h6>Address</h6>
                                            <p>Birmingham, United kingdom.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 order-1 order-md-2">
                            <form className="row">
                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <label className="group__label">First Name</label>
                                        <input type="text" placeholder="First Name" aria-invalid="false" aria-describedby="name_error" />
                                            <span id="name_error" className="form-error-msg mb-1 d-none ">Please enter firstname</span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <label className="group__label">Last Name</label>
                                        <input type="text" placeholder="Last Name" aria-invalid="false" aria-describedby="last_name_error" />
                                            <span id="last_name_error" className="form-error-msg mb-1 d-none ">Please enter lastname</span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <label className="group__label">Email</label>
                                        <input type="email" id="email"  aria-invalid="false" aria-describedby="email_error" placeholder="Email ID" value="" />
                                            <span id="email_error" className="form-error-msg mb-1 d-none ">Please enter valid email</span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="signinup-group sg-country-code  ">
                                        <div className="group__label">Phone No.</div>
                                        <div className="PhoneInput">
                                            <div className="PhoneInputCountry">
                                                <div aria-hidden="true" className="PhoneInputCountryIcon PhoneInputCountryIcon--border">
                                                </div>
                                                <div className="PhoneInputCountrySelectArrow">
                                                </div>
                                            </div>
                                            <input type="tel" placeholder="Phone Number" id="phone" className="PhoneInputInput" value="" />
                                        </div>
                                        <span id="phone_error" className="form-error-msg mb-1 d-none ">Please enter valid number</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="signinup-group  ">
                                        <label className="group__label">Message</label>
                                        <textarea id="message"  aria-invalid="false" aria-describedby="message_error" placeholder="Enter your message here.."></textarea>
                                        <span id="message_error" className="form-error-msg mb-1 d-none">Please enter message</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="widthfull common-btn-item cbi-fill m-0" style={{ width: '100%', maxWidth: '100%' }}><span>Submit</span></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Contact