import React, { useEffect, useRef, useState } from "react";
import * as Img from "../../components/Img";
import gsap from "gsap";
import AOS from "aos";
import "aos/dist/aos.css";

const Certificates = () => {

  const cardRef = useRef(null); // Ref for the evaluation card



  return (
    <>
      <div className="certificates p-relative p-80 custom-bg" >
        <img
          src={Img.leftscroll}
          className="left-s"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-3"
        />
        <img
          src={Img.rightscroll}
          className="right-s"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="3"
        />
        <div className="container-fluid row-4">
          <div
          data-aos="fade-up"
            className="custom-heading text-center"
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="8"
            data-scroll-class="appear"
            data-scroll-repeat="true"
          >
            Payout certificates
          </div>
          <div data-aos="fade-up">
 
          <div
            className="evaluation-card ec-card"
            ref={cardRef} // Attach ref for GSAP animation
            style={{
              transformStyle: "preserve-3d", // Required for 3D effect
              perspective: "1000px", // Perspective for parent element
            }}
            onMouseMove={(e) => {
              const card = cardRef.current;
              const rect = card.getBoundingClientRect(); // Get the card's dimensions and position
              const x = e.clientX - rect.left; // Mouse X position relative to the card
              const y = e.clientY - rect.top;  // Mouse Y position relative to the card

              // Calculate rotation angles based on cursor position within the card
              const rotateX = ((y / rect.height) - 0.5) * -20; // Adjust sensitivity
              const rotateY = ((x / rect.width) - 0.5) * 20;

              // Animate rotation using GSAP
              gsap.to(card, {
                rotateX,
                rotateY,
                transformPerspective: 1000, // Add perspective for 3D effect
                ease: "power2.out",
                duration: 0.2,
              });
            }}
            onMouseLeave={() => {
              const card = cardRef.current;

              // Reset card to default position when mouse leaves
              gsap.to(card, {
                rotateX: 0,
                rotateY: 0,
                ease: "power2.out",
                duration: 0.5,
              });
            }}
          >
            <img src={Img.Cirtione} alt="" style={{width: "100%", borderRadius: "6px"}} />
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificates;
