import React, { useState, useRef } from 'react';
import * as Img from '../../components/Img'
import { Link } from 'react-router-dom';

const Accordion = () => {
    const [openIndex, setOpenIndex] = useState(null); // Tracks the currently open accordion
    const refs = useRef([]); // Store refs for each accordion panel

    const data = [
        { title: 'What is Fundedfirm?', content: 'Fundedfirm is a Forex proprietary trading firm that provides capital to traders, allowing them to trade without risking their own money. Traders who meet specific criteria can manage large accounts and share in the profits.' },
        { title: 'How does Fundedfirm s funded account program work?', content: 'Fundedfirm is a Forex proprietary trading firm that provides capital to traders, allowing them to trade without risking their own money. Traders who meet specific criteria can manage large accounts and share in the profits.' },
        { title: 'What are the benefits of trading with Fundedfirm?', content: 'Fundedfirm offers the opportunity to trade with substantial capital, access to advanced trading platforms, and a profit-sharing model. This allows traders to scale their trading without risking their own funds.' },
        { title: 'What markets can I trade with Fundedfirm?', content: 'Traders can access various markets, including Forex, commodities, indices, and cryptocurrencies, depending on the firm s offerings.' },
        { title: 'What is the profit split with Fundedfirm?', content: 'Fundedfirm typically offers a profit split, where a percentage of the profits generated by the trader is paid to them. The exact split may vary depending on the account type and performance.' },
    ];

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the accordion
    };

    return (
        <>
            <div className='accordion-section p-80 custom-bg mt-4 pt-5'>
                <img src={Img.leftscroll} className='left-s'/>
                <img src={Img.rightscroll} className='right-s'/>
                <div className='container row-3'>
                    <div className='custom-heading ch-medium text-center' data-aos="fade-up">
                    Frequently Asked Question
                    </div>
                    <div className='custom-heading ch-xssmall text-center' data-aos="fade-up">
                    General Information
                    </div>
                    <div className="accordion">
                        {data.map((item, index) => (
                            <div key={index} className="accordion-item" data-aos="fade-up">
                                {/* Accordion Title */}
                                <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                                    <span>{item.title}</span>
                                    <span className="accordion-icon">{openIndex === index ? '-' : '+'}</span>
                                </div>

                                {/* Accordion Content */}
                                <div
                                    className="accordion-content"
                                    ref={(el) => (refs.current[index] = el)} // Save the ref for the current accordion
                                    style={{
                                        maxHeight: openIndex === index ? `${refs.current[index]?.scrollHeight}px` : '0',
                                        transition: 'max-height 0.3s ease-out',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {item.content}
                                </div>
                            </div>
                        ))}

                        <div className="buttons mt-5">
                            <Link to="/faq" className="btn-primary">
                                 Load More..
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accordion;
