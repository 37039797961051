import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as Img from "../../components/Img";

import UPI from "../../img/icon/UPI.png"
import BOOK from "../../img/icon/book.png"
import check from "../../img/icon/check.png"
import remove from "../../img/icon/remove.png"
import degree from "../../img/icon/degree.png"
import support from "../../img/icon/support.png"
import stockmarket from "../../img/icon/stock-market.png"
import switchvdbv from "../../img/icon/switch.png"

const Ouradvantages = () => {

  return (
    <>
      <div className="ouradvantage p-80 overflowhidden">
        <div className="maxcadvzdv">

          <div className="oat-box row-3" >
            <div className="buttons" data-aos="fade-up"><Link to="/" className="btn-primary">Our Advantages</Link></div>
            <div className="custom-heading ch-medium" data-aos="fade-up"><span>How does <img src={Img.logo} alt="logo" /></span> compare with other companies?</div>
          </div>

          <div className="oat-table">
            <div className="oat-card">
              <div className="oat-grid" data-aos="fade-up">
                {/* Column 1 */}
                <div className="column">
                  <div className="cc-title"></div>
                  <div className="cc-card">
                    {/* <span> */}
                      <img style={{width:"20px",height:"20px",marginRight:"10px",position: "relative",top: "-1px"}} src={Img.Leptop2} alt="" />
                    {/* </span> */}
                    {" "}
                    MT5 Trading Platform
                  </div>
                  <div className="cc-card">
                      <img style={{width:"20px",height:"20px",marginRight:"10px",position: "relative",top: "-1px"}} src={UPI} alt="" />
                    {/* <span>
                      <img src={Img.Rightarrow} alt="" />
                      </span> */}
                    {" "}
                    UPI Payouts
                  </div>
                  <div className="cc-card">
                      <img style={{width:"20px",height:"20px",marginRight:"10px",position: "relative",top: "-1px"}} src={BOOK} alt="" />
                    {/* <span>
                      <img src={Img.Rightarrow} alt="" />
                      </span>{" "} */}
                    Consistency Rule
                  </div>
                  <div className="cc-card">
                      <img style={{width:"20px",height:"20px",marginRight:"10px",position: "relative",top: "-1px"}} src={stockmarket} alt="" />
                    {/* <span>
                      <img src={Img.Rightarrow} alt="" />
                      </span> */}
                    {" "}
                    News Trading Allowed
                  </div>
                  <div className="cc-card">
                      <img style={{width:"20px",height:"20px",marginRight:"10px",position: "relative",top: "-1px"}} src={switchvdbv} alt="" />
                    {/* <span>
                      <img src={Img.Rightarrow} alt="" />
                    </span> */}
                    {" "}
                    Swap & Comission
                  </div>
                  <div className="cc-card">
                      <img style={{width:"20px",height:"20px",marginRight:"10px",position: "relative",top: "-1px"}} src={support} alt="" />
                    {/* <span>
                      <img src={Img.Rightarrow} alt="" />
                    </span> */}
                    {" "}
                    Ultrafast live support
                  </div>
                  <div className="cc-card">
                    {/* <span> */}
                      <img style={{width:"20px",height:"20px",marginRight:"10px",position: "relative",top: "-1px"}} src={degree} alt="" />
                      {/* <img src={Img.Rightarrow} alt="" /> */}
                    {/* </span> */}
                    {" "}
                    Scaling Rule
                  </div>
                </div>

                {/* Column 2 */}
                <div className="column fvsxfbdt highlighted text-center">
                  <div className="logo-wrapper">
                    <div className="logo-circle">
                      <div className="logo-icon">
                        <img style={{width:"100%",padding: "2px"}} src={Img.lvsfbsogo} alt='' />
                      </div>
                    </div>
                  </div>
                  <div className="oat-center-box">
                    <div className="cc-title">
                      When you trade <span>with FundedFirm</span>
                    </div>
                    <div className="cc-center-box">
                      <div className="cc-inner-content">
                        <div className="cci-box">
                          <div className="cc-card">Yes<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={check} alt="" /></div>
                          <div className="cc-card">Yes<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={check} alt="" /></div>
                          <div className="cc-card">No<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={remove} alt="" /></div>
                          <div className="cc-card">Yes<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={check} alt="" /></div>
                          <div className="cc-card">No<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={remove} alt="" /></div>
                          <div className="cc-card">Yes<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={check} alt="" /></div>
                          <div className="cc-card">No<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={remove} alt="" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Column 3 */}
                <div className="column fvsxfbdt text-center">
                  <div className="cc-title">
                    When you trade<span>with Others</span>
                  </div>
                  <div className="cc-card">No<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={remove} alt="" /></div>
                  <div className="cc-card">No<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={remove} alt="" /></div>
                  <div className="cc-card">Yes<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={check} alt="" /></div>
                  <div className="cc-card">No<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={remove} alt="" /></div>
                  <div className="cc-card">Yes<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={check} alt="" /></div>
                  <div className="cc-card">No<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={remove} alt="" /></div>
                  <div className="cc-card">Yes<img style={{width:"18px",height:"18px",marginLeft:"10px",position: "relative",top: "-1px"}} src={check} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ouradvantages;
