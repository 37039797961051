import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as Img from "../../components/Img";
import Icon from "../../components/Icon";


const Banner = () => {

  return (
    <div className="landing-page mt-5 mt-sm-0">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="background-video"
        id="background-video"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <source src={Img.Banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="banner-contnet">
        <div
          className="banner-h"

        >
          <h1 data-aos="fade-up" data-aos-delay="1500" data-aos-duration="1500"  className="custom-heading">
            FundedFirm : Your Gateway to <br />
            <span>Forex Trading</span>
          </h1>
          <p data-aos="fade-up"  data-aos-delay="1700" data-aos-duration="1500">
            As a top-rated prop firm, FundedFirm specializes in providing funded
            accounts to skilled forex traders,{" "}
            <span>
              giving them the opportunity to trade with substantial capital and
              advanced resources.
            </span>
          </p>
        </div>
        <div
          className="buttons" data-aos="fade-up"  data-aos-delay="2000" data-aos-duration="1500"
        >
          <a href="#choosebestplan" className="btn-primary">
            Buy Challenge{" "}
            <span>
              {/* <Icon.ArrowrightSvg /> */}
            </span>
          </a>
          <Link to="https://telegram.me/fundedfirm" target="_blank" className="btn-secondary">
            Telegram
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
