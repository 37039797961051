import React from "react";
import { Link } from "react-router-dom";
import * as Img from "../../components/Img";


const Howitwork = () => {
   
    return (
        <div className="how-it-work p-80 overflowhidden">
            {/* <img
                src={Img.leftscroll}
                className="left-s"
                alt="Left Scroll"
                data-scroll
                data-scroll-direction="horizontal"
                data-scroll-speed="-6"
            />
            <img
                src={Img.rightscroll}
                className="right-s"
                alt="Right Scroll"
                data-scroll
                data-scroll-direction="horizontal"
                data-scroll-speed="6"
            /> */}
            <div className="container">
                <div className="hiw-inner">
                    <div className="buttons"  data-aos="fade-up">
                        <Link to="/" className="btn-primary">
                            Key Highlights
                        </Link>
                    </div>
                    <div className="custom-heading ch-medium" id="quote"  data-aos="fade-up">
                        At FundedFirm, we are more than just a prop firm—we are your partner in forex trading success. By providing funded accounts and expert support, we help you overcome financial barriers and reach your trading potential with confidence and capital.
                    </div>
                    <div className="hiw-inner-box">
                        <div className="row">
                            <div className="col-md-6"  data-aos="fade-right">
                                <div
                                    className="hib-box box-left one"
                                >
                                    <div className="custom-heading ch-small">
                                        <span>+</span>Safe & Secure
                                    </div>
                                    <div className="custom-heading ch-xssmall">
                                        Experience Ultra-Fast and Secure Trade Execution with Raw Spreads and No Commissions
                                    </div>
                                    {/* <img src={Img.Line} alt="line" className="line" />
                                    <img src={Img.Glow} alt="glow" className="glow" /> */}
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="fade-left">
                                <div
                                    className="hib-box box-right two"
                                >
                                    <div className="custom-heading ch-small">
                                        <span>+</span>Trade on MT5
                                    </div>
                                    <div className="custom-heading ch-xssmall">
                                        FundedFirm provides the Meta-Trader 5 platform, ensuring you have the best trading experience.
                                    </div>
                                    {/* <img src={Img.Line} alt="line" className="line" /> */}
                                    {/* <img src={Img.Glow} alt="glow" className="glow" /> */}
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="fade-right">
                                <div
                                    className="hib-box box-left three"
                                >
                                    <div className="custom-heading ch-small">
                                        <span>+</span>News Trading
                                    </div>
                                    <div className="custom-heading ch-xssmall">
                                        Take advantage of high-impact news with FundedFirm's news trading, allowing you to capitalize on significant market movements quickly.
                                    </div>
                                    {/* <img src={Img.Line} alt="line" className="line" /> */}
                                    {/* <img src={Img.Glow} alt="glow" className="glow" /> */}
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="fade-left">
                                <div
                                    className="hib-box box-right four"
        
                                >
                                    <div className="custom-heading ch-small">
                                        <span>+</span>Fast payouts
                                    </div>
                                    <div className="custom-heading ch-xssmall">
                                        That payouts are given on a monthly basis and processing time is 24 hours. Makes Your journey more Smooth and Successful.
                                    </div>
                                    {/* <img src={Img.Line} alt="line" className="line" /> */}
                                    {/* <img src={Img.Glow} alt="glow" className="glow" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Howitwork;
