import React from 'react'
import { Link } from 'react-router-dom'

const Privacypolicy = () => {
    return (
        <>
            <section className="py-5 relative pt-4 main-section">
                <div className="container-lg cl-custome">
                    <div className="textlayout-main-bx">
                        <div className="breadcrumb-bx">
                            <a className="breadcrumb-link" href="/">Home</a>
                            <div className="breadcrumb-link breadcrumb-active">/ Privacy Policy</div>
                        </div>
                        <div className="common-heading-bx">
                            <div className="common-heading ch-color-gradient mont-font mx-auto">Privacy Policy</div>
                        </div>
                    </div>
                </div>

                <div className="container-xl pb-3">
                    <div className="common-heading-bx">
                        <p className="common-sub-content">Protecting your private information is our priority. This Statement of Privacy applies to fundedfirm.com, and governs data collection and usage.</p>
                        <p className="common-sub-content">FundedFirm website is an eCommerce site. By using FundedFirm website, you consent to the data practices described in this Statement and all extensions and addendums to this Statement.</p>

                        <div className="main-heading pl-0 ml-0">Collection Of Your Personal Information</div>
                        <p className="common-sub-content">In order to better provide you with products and services offered, FundedFirm may collect personally identifiable information, such as your:</p>
                        <div className="pl-3">
                            <p className="common-sub-content">1. First and Last Name</p>
                            <p className="common-sub-content">2. Home Address</p>
                            <p className="common-sub-content">3. Mailing Address</p>
                            <p className="common-sub-content">4. E-mail Address</p>
                            <p className="common-sub-content">5. Phone Number</p>
                            <p className="common-sub-content">6. Financial Information</p>
                        </div>
                        <p className="common-sub-content">If you purchase FundedFirm products and services, we collect billing and credit card information. This information is used to complete the purchase transaction or issue payments to you. We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstake or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To with, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future for the same purpose.</p>

                        <div className="main-heading pl-0 ml-0">Use Of Your Personal Information</div>
                        <p className="common-sub-content">FundedFirm collects and uses your personal information to operate and deliver the services you have requested. FundedFirm may also use your personally identifiable information to inform you of other products or services available from FundedFirm and its affiliate</p>

                        <div className="main-heading pl-0 ml-0">Sharing Information With Third Parties</div>
                        <p className="common-sub-content">FundedFirm may sell, rent, or lease customer information to third parties for the following reasons: We will collect this data and provide it to third parties if it is a necessary step in the User account creation process, contributes to the ease of use, reliability, or functionality of our products, or if it is of mutual interest to FundedFirm. FundedFirm may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. FundedFirm may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to FundedFirm, and they are required to maintain the confidentiality of your information. FundedFirm may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on FundedFirm or the Site; (b) protect and defend the rights or property of FundedFirm; and/or (c) act under exigent circumstances to protect the personal safety of users of FundedFirm, or the public.</p>

                        <div className="main-heading pl-0 ml-0">Tracking User Behavior</div>
                        <p className="common-sub-content">FundedFirm may keep track of the websites and pages our users visit within FundedFirm, in order to determine what FundedFirm services are the most popular. This data is used to deliver customized content and advertising within FundedFirm to customers whose behavior indicates that they are interested in a particular subject area.</p>

                        <div className="main-heading pl-0 ml-0">Automatically Collected Information</div>
                        <p className="common-sub-content">Information about your computer hardware and software may be automatically collected by FundedFirm. This information can include your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of Page 3 of 5 services, to maintain the quality of the service, and to provide general statistics regarding the use of the FundedFirm website.</p>

                        <div className="main-heading pl-0 ml-0">Use Of Cookies</div>
                        <p className="common-sub-content">FundedFirm website may use cookies to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize FundedFirm pages, or register with FundedFirm site or services, a cookie helps FundedFirm to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same FundedFirm website, the information you previously provided can be retrieved, so you can easily use FundedFirm features that you customized. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of FundedFirm services or websites you visit.</p>

                        <div className="main-heading pl-0 ml-0">Links</div>
                        <p className="common-sub-content">This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>

                        <div className="main-heading pl-0 ml-0">Security Of Your Personal Information</div>
                        <p className="common-sub-content">FundedFirm secures your personal information from unauthorized access, use, or disclosure. FundedFirm  uses the following methods for this purpose:</p>

                        <div className="main-heading pl-0 ml-0">SSL Protocol</div>
                        <p className="common-sub-content">When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>

                        <div className="main-heading pl-0 ml-0">Right To Deletion</div>
                        <p className="common-sub-content">Subject to certain exceptions and for those individuals who meet jurisdictional and legal requirements, on receipt of a verifiable request from you, we will:</p>
                        <p className="common-sub-content">Delete your personal information from our records; and Direct any service providers to delete your personal information from their records.</p>

                        <div className="main-heading pl-0 ml-0">Children Under Thirteen</div>
                        <p className="common-sub-content">FundedFirm does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
                        <div className="text-white">Opt-Out & Unsubscribe from Third Party Communications</div>
                        <p className="common-sub-content">We respect your privacy and give you an opportunity to opt out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from third-party partners of FundedFirm by contacting us here:</p>
                        <p className="common-sub-content">please email us at <Link className="please-email" href="mailto:info@fundedfirm.com" target="_blank">info@fundedfirm.com</Link></p>

                        <div className="main-heading pl-0 ml-0">E-Mail Communications</div>
                        <p className="common-sub-content">From time to time, FundedFirm may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from FundedFirm  or click on a link therein.</p>
                        <p className="common-sub-content">If you would like to stop receiving marketing or promotional communications via email from FundedFirm , you may opt out of such communications Customers may unsubscribe from emails by clicking on the unsubscribe button found at the bottom of each email.</p>

                        <div className="main-heading pl-0 ml-0">External Data Storage</div>
                        <p className="common-sub-content">We may store your data on servers provided by third-party hosting vendors with whom we have contracted.</p>

                        <div className="main-heading pl-0 ml-0">Changes To This Statement</div>
                        <p className="common-sub-content">FundedFirm reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, placing a prominent notice on our website, and/or updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy</p>

                        <div className="main-heading pl-0 ml-0">Contact Information</div>
                        <p className="common-sub-content">FundedFirm welcomes your questions or comments regarding this Statement of Privacy. If you believe that FundedFirm has not adhered to this Statement, please contact FundedFirm at support@fundedfirm.com This statement is effective as of June 1st, 2024.</p>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Privacypolicy