import React from 'react'
import { Link } from 'react-router-dom'

const Amlpolicy = () => {
    return (
        <>
            <section className="py-5 relative pt-4 main-section">
                <div className="container-lg cl-custome">
                    <div className="textlayout-main-bx">
                        <div className="breadcrumb-bx">
                            <Link className="breadcrumb-link" to="/">Home</Link>
                            <div className="breadcrumb-link breadcrumb-active">/ AML Policy</div>
                        </div>
                        <div className="common-heading-bx">
                            <div className="common-heading ch-color-gradient mont-font mx-auto">AML Policy</div>
                        </div>
                    </div>
                </div>

                <div className="container-xl pb-3">
                    <div className="common-heading-bx">
                        <div className="main-heading pl-0 ml-0">Policy Statement</div>
                        <p className="common-sub-content">FundedFirm is firmly committed to the prevention of money laundering, terrorist financing, and the evasion of sanctions. Unwavering compliance with applicable laws and regulations shall serve to safeguard  - FundedFirm's reputation and uphold public trust in  - FundedFirm.</p>
                        <p className="common-sub-content">Therefore, it is advisable for FundedFirm to establish an anti-money laundering (AML) policy that delineates the prescribed measures to be observed, aiming to ensure the efficient execution of guidelines that support the attainment of overarching organizational goals, while guaranteeing conformity with the regulatory structure governing designated non-financial businesses and professions (DNFBP) in the United Arab Emirates (UAE). The scope of the AML policy should encompass all entities in which FundedFirm holds ownership interests.</p>

                        <div className="main-heading pl-0 ml-0">Policy Objectives</div>
                        <ul>
                            <li>To establish precise and unambiguous policies for FundedFirm concerning the matters of money laundering, terrorist financing, and the avoidance of sanctions.</li>
                            <li>To provide clear and concise explanations of the compliance responsibilities that all employees are obligated to fulfill.</li>
                            <li>To offer guidance to employees regarding their daily business activities in alignment with legal requirements.</li>
                            <li>To foster a work environment that upholds a culture of adherence to legal regulations in the manner in which employees execute their duties.</li>
                        </ul>

                        <div className="main-heading pl-0 ml-0">Definition of Money Laundering</div>
                        <p className="common-sub-content">As per the provisions outlined in Article 2 of the Anti-Money Laundering (AML) Law, the offense of money laundering is defined as the engagement in any of the following activities with the knowledge that the funds involved are derived from a criminal act:</p>
                        <ul>
                            <li>transfers or moves the proceeds, or disposes of the funds/proceeds intending to conceal or disguise their source</li>
                            <li>conceals or disguises the true nature, source or location of the proceeds including the manner of disposal</li>
                            <li>acquires, possesses or uses the proceeds on receiving them</li>
                            <li>assists the person who committed the felony/misdemeanor to escape punishment.</li>
                        </ul>
                        <p className="common-sub-content">It is essential to note that money laundering is always an intentional act and cannot be committed through mere negligence. However,  - FundedFirm acknowledges that acts exhibiting gross negligence, particularly those involving the failure to report suspicions of criminality to the Financial Intelligence Unit (FIU), may incur criminal liability.</p>

                        <div className="main-heading pl-0 ml-0">Defenses</div>
                        <p className="common-sub-content">The Regulations do not provide any legally established defenses specific to charges of money laundering. The defenses applicable under United Arab Emirates (UAE) law for money laundering charges are not distinct from those available for any other criminal offense.</p>
                        <p className="common-sub-content">It is important to note that criminal proceedings related to money laundering cannot be resolved through settlement or plea agreements. Once the Public Prosecutor determines that actions amount to a money laundering offense, prosecutorial discretion is not allowed, and the Public Prosecutor is obligated to refer the case to the criminal courts.</p>

                        <div className="main-heading pl-0 ml-0">Transaction Monitoring System</div>
                        <p className="common-sub-content">FundedFirm has implemented a transaction monitoring system designed to identify any transactions that appear unusual or raise suspicion. On a daily basis, transaction reports in .csv format are downloaded and subjected to manual review. However, with the aim of enhancing efficiency and effectiveness,  - FundedFirm intends to deploy an automated transaction monitoring system by the year 2023. Currently, the manual review process is facilitated through the utilization of the 'Veriff' Identity Verification Service.</p>

                        <div className="main-heading pl-0 ml-0">Reporting Suspicious Transactions</div>
                        <p className="common-sub-content">FundedFirm fulfills its obligation to report any transactions deemed suspicious to the appropriate authority through the established channel of email communication. Such transactions are promptly reported to The Central Bank of the United Arab Emirates, utilizing the intermediary service of our designated banking institution, Bank Mashreq Bank PSC, to facilitate the transmission of the requisite information.</p>
                        <p className="common-sub-content">Although FundedFirm is currently in the third year of its operational existence, a robust and diligent approach to document retention has been implemented. Consequently, all relevant records and documents shall be securely preserved for a minimum period of five years, in adherence to legal and regulatory requirements. This practice ensures the availability of necessary information for future reference, compliance audits, potential legal proceedings, or any other legitimate purpose within the prescribed time frame.</p>

                        <div className="main-heading pl-0 ml-0">Risk Rating</div>
                        <p className="common-sub-content">Within FundedFirm, a distinct and dedicated risk management unit exists, tasked with the meticulous manual examination of individual client profiles. With an emphasis on comprehensive risk assessment, due diligence is conducted thoroughly on the customers subsequent to evaluating their risk rating. This process allows to effectively assess and mitigate potential risks associated with the clientele in compliance with applicable legal and regulatory frameworks.</p>

                        <div className="main-heading pl-0 ml-0">Resolutions and Sanctions</div>
                        <p className="common-sub-content">In situations where there is non-compliance with the established standards, the Regulations offer administrative sanctions to ensure the appropriate implementation of the regulatory framework. These sanctions may include various measures such as warnings, monetary fines, limitations or suspension of business activities, apprehension of board members and senior management who are held accountable for violating the regulations, as well as curtailment of the authority of the board and senior management through the appointment of a temporary observer.</p>
                        <p className="common-sub-content">In the event of a conviction for a money laundering offense, the Anti-Money Laundering (AML) Law prescribes punitive measures which encompass financial penalties ranging from AED 100,000 to AED 10 million, along with the possibility of imprisonment for a period of up to 10 years.</p>

                        <div className="main-heading pl-0 ml-0">FundedFirm’s Obligations</div>
                        <ul>
                            <li>Identify potential risks associated with criminal activities within its operations.</li>
                            <li>Conduct ongoing assessments of these identified risks.</li>
                            <li>Establish internal controls and policies to effectively manage the identified risks.</li>
                            <li>Implement appropriate due diligence procedures, as further elaborated below.</li>
                            <li>Adhere to and implement any United Nations (UN) directives pertaining to the prevention of financing terrorism and the proliferation of weapons of mass destruction.</li>
                        </ul>

                        <div className="main-heading pl-0 ml-0">Avoiding shell bank/organization</div>
                        <p className="common-sub-content">FundedFirm conducts a meticulous manual review and approval of each transaction, applying the principles of due diligence. This rigorous procedure serves as a preventive measure against establishing business relationships with shell banks or organizations.</p>
                        <p className="common-sub-content">On an annual basis, employees at FundedFirm should be provided training on AML/CFT. The training includes:</p>
                        <ul>
                            <li>Identification and reporting of transactions that must be reported to government authorities</li>
                            <li>Examples of different forms of money laundering / terrorist financing involving your products / services.</li>
                            <li>Information on internal policies to prevent money laundering / terrorist financing and escalate suspicious activity and red flags.</li>
                        </ul>
                        <p className="common-sub-content">FundedFirm should maintain documentation of its training sessions, which may include records of attendance and pertinent instructional materials. The company should ensure that any newly enacted or amended anti-money laundering/counter-terrorism financing (AML/CFT) laws, as well as updates to existing AML/CFT policies or practices, are communicated to the appropriate staff members.</p>

                        <div className="text-white">Designated Compliance Team</div>
                        <p className="common-sub-content">The Risk Management team should regularly perform compliance testing to ensure adherence to legal requirements. A Designated Compliance Officer should hold responsibility for the comprehensive Anti-Money Laundering/Combating the Financing of Terrorism (AML/CFT) program, including the coordination and oversight of its day-to-day operations.</p>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Amlpolicy