import React from 'react'
import * as Img from '../../components/Img';
import Icon from '../../components/Icon';

const Affiliateprogram = () => {
    return (
        <>
            <div className="main-section">
                <section className="py-5 relative">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-6 col-md-12" style={{ margin: 'auto' }}>
                                <div className="common-heading-bx center-head-sub">
                                    <div className="common-heading ch-big ch-color-gradient mx-auto mx-md-0"
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        Affiliate Program
                                    </div>
                                    <div className="common-sub-heading">
                                        Find talented traders from around the world to join us on our journey, and take advantage of the great benefits we provide you for each referral.
                                    </div>
                                </div>
                                <div className="d-flex justify-content-md-start justify-content-center flex-wrap cbi-gap-10px mt-5">
                                    <a href="https://my.fundedfirm.com/" className="mar-auto-btn" target="_blank" rel="noopener noreferrer">
                                        <div className="common-btn-item cbi-enquiry" type="button">
                                            <span>Get Started</span>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 mt-4">
                                <div className="row justify-content-center mt-4 mt-md-0 mx-lg-0 px-2 px-lg-0">
                                    <div className="col-sm-6 col-6 p-2 col-auto">
                                        <div className="card-item-flip">
                                            <div className="ctf-inner">
                                                <div>
                                                    <div className="card-item cif-after">
                                                        <img className="card-i-img img-32 img-w-h-60px" src={Img.afilat1} alt="afilat1"/>
                                                        <div className="card-i-text">
                                                            Earn 15% commission when a person buys their first account.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-6 p-2 col-auto">
                                        <div className="card-item-flip">
                                            <div className="ctf-inner">
                                                <div>
                                                    <div className="card-item cif-after">
                                                        <img className="card-i-img img-w-h-60px" src={Img.afilat2} alt="afilat2" />
                                                        <div className="card-i-text">
                                                            Track your earnings with ease through our dashboard on the app.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-6 p-2 col-auto">
                                        <div className="card-item-flip">
                                            <div className="ctf-inner">
                                                <div className="ctf-inner-height">
                                                    <div className="card-item cif-after">
                                                        <img className="card-i-img img-w-h-60px" src={Img.afilat3} alt="afilat3" />
                                                        <div className="card-i-text">
                                                            Promote easily with any method(s) using your unique link.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5 relative">
                <div className="container-xl conta-max-wid">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="mine-section-sticky">
                                <div className="common-heading-bx center-head-sub">
                                    <div
                                        className="common-heading ch-big ch-color-gradient mx-auto mx-md-0"
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        How it works?
                                    </div>
                                    <div className="common-sub-heading">
                                        Learn how the whole referral process works.
                                    </div>
                                </div>
                                <div className="d-flex justify-content-md-start justify-content-center flex-wrap cbi-gap-10px mt-5">
                                    <a
                                        href="https://my.fundedfirm.com/"
                                        className="mar-auto-btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="common-btn-item cbi-enquiry" type="button">
                                            <span>Get Started</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 mt-4 col-md-12">
                            <div className="step-main-box">
                                <div className="step-item-main-box">
                                    <div className="simb-inner">
                                        <div className="simb-i-point"></div>
                                        <div className="simb-i-step">Step 1</div>
                                        <img className="simb-i-img" src={Img.step1} alt="Step 1"/>
                                        <div className="simb-i-title">Generate your affiliate link</div>
                                        <div className="simb-i-text">
                                            Purchase any challenge on our app and you will automatically be eligible for a unique link to start promoting on your favourite platforms.
                                        </div>
                                    </div>
                                </div>
                                <div className="step-item-main-box">
                                    <div className="simb-inner">
                                        <div className="simb-i-point"></div>
                                        <div className="simb-i-step">Step 2</div>
                                        <img className="simb-i-img" src={Img.step2} alt="Step 2"/>
                                        <div className="simb-i-title">Promote FF</div>
                                        <div className="simb-i-text">
                                            Promote FF online using any social media platform, a unique cookie will be set on the user’s side so even if they buy later, you will be eligible for a commission.
                                        </div>
                                    </div>
                                </div>
                                <div className="step-item-main-box">
                                    <div className="simb-inner">
                                        <div className="simb-i-point"></div>
                                        <div className="simb-i-step">Step 3</div>
                                        <img className="simb-i-img" src={Img.step3} alt="Step 3"/>
                                        <div className="simb-i-title">Monitor Results</div>
                                        <div className="simb-i-text">
                                            You can track all the purchases that have happened through your link by visiting the affiliate dashboard.
                                        </div>
                                    </div>
                                </div>
                                <div className="step-item-main-box">
                                    <div className="simb-inner">
                                        <div className="simb-i-point"></div>
                                        <div className="simb-i-step">Step 4</div>
                                        <img className="simb-i-img" src={Img.step4} alt="Step 4"/>
                                        <div className="simb-i-title">Payout Time</div>
                                        <div className="simb-i-text">
                                            You can request a payout on the payouts page when you are eligible for a payout.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Community Section */}
            <section className="py-5 pr">
                <div className="container-xl py-5">
                    <div className="trader-absolute-bg-color"></div>
                    <div className="main-heading-our">
                        <div className="sub-heading pl-0">Our Capital, Your Profits</div>
                        <p className="text-center sub-text">
                            Get funded today and start achieving your financial goals.
                        </p>
                    </div>
                    <div className="common-highlight-btns">
                        <a className="chb-item chb-color1" href="https://telegram.me/fundedfirm" target="_blank" rel="noopener noreferrer" >
                            <img src={Img.telegram} alt="Telegram" /> Join Telegram Community</a>
                        <a className="chb-item chb-color2" href="https://my.fundedfirm.com/register" target="_blank" rel="noopener noreferrer">
                            <img src={Img.discord} alt="Discord" />
                            Join Discord Community
                        </a>
                    </div>
                </div>
            </section>

            </div>
        </>
    )
}

export default Affiliateprogram