import React from 'react'
import * as Img from '../../components/Img'
import { Link } from 'react-router-dom';

const Blog = () => {
    return (
        <>
            <section className="relative pt-4">
                <div className="container cl-custome">
                    <div className="textlayout-main-bx">
                        <div className="breadcrumb-bx">
                            <Link className="breadcrumb-link" to="/">Home</Link>
                            <div className="breadcrumb-link breadcrumb-active">/ Blog</div>
                        </div>
                        <div className="common-heading-bx">
                            <div className="common-heading ch-color-gradient mont-font mx-auto">Blog</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container cl-custom pb-3">
                    <div className="row">

                        <div className="col-xl-3 col-lg-4 col-sm-6 mb-4 px-2">
                            <div className="blog-box">
                                <Link to="/blog/maximizeyour" className="blog-item our-work-item wow fadeInUp animated">
                                    <div className="blog-imgs">
                                        <img src={Img.Blog1} alt="blog1" />
                                    </div>
                                    <div className="blog-lable">
                                        "Maximize Your Trading Potential: How FundedFirm’s Capital Can Propel Your Forex Career"
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-sm-6 mb-4 px-2">
                            <div className="blog-box">
                                <Link to="/blog/yourbestallies" className="blog-item our-work-item wow fadeInUp animated">
                                    <div className="blog-imgs">
                                        <img src={Img.Blog2} alt="blog2" />
                                    </div>
                                    <div className="blog-lable">
                                        "Why FundedFirm’s Trading Rules are Your Best Allies for Success"
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-sm-6 mb-4 px-2">
                            <div className="blog-box">
                                <Link to="/blog/magicbehind" className="blog-item our-work-item wow fadeInUp animated">
                                    <div className="blog-imgs">
                                        <img src={Img.Blog3} alt="blog3" />
                                    </div>
                                    <div className="blog-lable">
                                        "The Magic Behind FundedFirm’s Lightning-Fast Execution and Raw Spreads"
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-sm-6 mb-4 px-2">
                            <div className="blog-box">
                                <Link to="/blog/frombeginner" className="blog-item our-work-item wow fadeInUp animated">
                                    <div className="blog-imgs">
                                        <img src={Img.Blog4} alt="blog4" />
                                    </div>
                                    <div className="blog-lable">
                                        "From Beginner to Pro: How FundedFirm Nurtures New Traders to Forex Legends"
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-sm-6 mb-4 px-2">
                            <div className="blog-box">
                                <Link to="/blog/performancebased" className="blog-item our-work-item wow fadeInUp animated">
                                    <div className="blog-imgs ">
                                        <img src={Img.Blog5} alt="blog5" />
                                    </div>
                                    <div className="blog-lable">
                                        "Performance-Based Rewards at FundedFirm: The Sweetest Incentive for Trading Success"
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
        </>
    )
}

export default Blog
